export const mapboxInitialState = {
    map: null,
    draw: null,
    mapRef: null,
    geojson: {},
    selectedPoints: [],
    newPolygon: false,
    deletePolygon: false
}

const mapboxReducer = (state, action) => {
    switch (action.type) {
        case "SET_MAPBOX_ALL": {
            const { map, draw, mapRef, geojson, selectedPoints, newPolygon, deletePolygon } = action;
            return { ...state, map, draw, mapRef, geojson, selectedPoints, newPolygon, deletePolygon };
        }
        case "SET_MAPBOX_MAP": {
            const { map } = action;
            return { ...state, map };
        }
        case "SET_MAPBOX_DRAW": {
            const { draw } = action;
            return { ...state, draw };
        }
        case "SET_MAPBOX_MAP_REF": {
            const { mapRef } = action;
            return { ...state, mapRef };
        }
        case "SET_MAPBOX_GEOJSON": {
            const { geojson } = action;
            return { ...state, geojson };
        }
        case "SET_MAPBOX_SELECTED_POINTS": {
            const { selectedPoints } = action;
            return { ...state, selectedPoints };
        }
        case "SET_MAPBOX_NEW_POLYGON": {
            const { newPolygon } = action;
            return { ...state, newPolygon };
        }
        case "SET_MAPBOX_DELETE_POLYGON": {
            const { deletePolygon } = action;
            return { ...state, deletePolygon };
        }
        default:
            return state;
    }
};

export default mapboxReducer;
