import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '75%',
		// height: '70%',
		minHeight: '70%',
		backgroundColor: 'white',
		boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075),0 0 10px gray',
		padding: 25,
		display: 'flex',
		justifyContent: 'center',

		"@media (max-width: 1024px)": {
			padding: 20,
			width: '80%'
		},

		"@media (max-width: 768px)": {
			padding: 15,
			width: '85%'
		},

		"@media (max-width: 425px)": {
			padding: 10,
			width: '90%'
		}
	},
	container: {
		width: '100%',
		height: '100%',
	},
	top_container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexGrow: 1
	},
	add_button_container: {
		display: 'flex',
		cursor: 'pointer',
		minWidth: 100,
		minHeight: 34
	},
	add_button_icon_container: {
		backgroundColor: theme.palette.blue_flash,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0px 5px 0px 5px',
		borderRadius: '5px 0px 0px 5px',
	},
	add_button_text_container: {
		backgroundColor: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0px 10px 0px 10px',
		border: `2px solid ${theme.palette.blue_flash}`,
		borderRadius: '0px 5px 5px 0px',

		'&:hover': {
			backgroundColor: '#F2F2F2'
		}
	},
	search_container: {
		display: 'flex',
		height: 34,
		alignItems: 'center'
	},
	add_icon: {
		color: 'white'
	},
	add_text: {
		color: theme.palette.blue_flash,
		fontWeight: 'bold',
		fontSize: 15,

		"@media (max-width: 1024px)": {
			fontSize: 12.5
		},

		"@media (max-width: 768px)": {
			fontSize: 10
		}
	},
	disconnect_icon: {
		cursor: 'pointer',
		padding: 5,
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.ghost}`,
		color: theme.palette.blue_flash,
		borderRadius: 5,
		marginRight: 5,

		'&:hover': {
			backgroundColor: '#F2F2F2'
		}
	},
	input_base: {
		marginLeft: 15,
		marginRight: 15,
		borderRadius: 5,
		minWidth: 100,
		height: '100%',
		padding: '0px 10px 0px 10px',
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.ghost}`,

		'&:hover': {
			boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.blue_flash}`,
		}
	},
	input_base_focus: {
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.blue_flash}`,
	},
	table_align_center: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%'
	},
	table_container: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
		margin: '25px 0px 25px 0px',
	},
	table: {
		'& .MuiTable-stickyHeader': {
			backgroundColor: theme.palette.blue_flash,
		}
	},
	table_head: {
		backgroundColor: theme.palette.blue_flash,
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.ghost}`
	},
	table_head_row: {
		whiteSpace: 'nowrap',
		backgroundColor: theme.palette.blue_flash,
	},
	table_body_row: {
		whiteSpace: 'nowrap',
		cursor: 'pointer'
	},
	table_head_cell: {
		border: 0,
		color: 'white',
		fontWeight: 'bold',
	},
	table_body_cell: {
		border: 0,
		color: theme.palette.ghost,
		fontWeight: 'bold'
	},
	pagination_container: {
		display: 'flex',
		justifyContent: 'center',
		// position: 'absolute',
		// bottom: 0,
		width: '100%'
	}
}));

export default useStyles;
