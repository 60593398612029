import React, { useState } from 'react';
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import { LocationOn, GetApp } from '@material-ui/icons';
import { saveAs } from 'file-saver';

import { getPointPdf } from '../../../api/GET';

import useStyles from './styles/MarkerPopup';

const months = [
   "janvier",
   "février",
   "mars",
   "avril",
   "mai",
   "juin",
   "juillet",
   "août",
   "septembre",
   "octobre",
   "novembre",
   "décembre"
]

const MarkerPopup = (props) => {

    const [downloadLoading, setDownloadLoading] = useState(false);

    const { ID, Matérialisation, Statut, Ligne, PK } = props.properties;
    const { coordinates } = props.geometry;
    const StatusDate = new Date(props.properties['Epoque du statut']);

    const classes = useStyles();

    const onItinary = () => {
        const a = document.createElement('a');
        a.href = `https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${coordinates[1]},${coordinates[0]}`;
        a.target = '_blank';
        a.rel = 'nofollow noopener noreferrer';
        a.click();
    }

    const onDownloadPDF = async () => {
        setDownloadLoading(true);
        const res = await getPointPdf(ID);
        if (res)
            saveAs(res, `${ID}.pdf`);
        setDownloadLoading(false);
    }

    const formatedDate = `${months[StatusDate.getMonth() - 1]} ${StatusDate.getFullYear()}`

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Typography className={classes.title}>Point {ID}</Typography>
                <Typography className={classes.text}>{Matérialisation}</Typography>
                <Typography className={classes.text}>{Statut} en {formatedDate}</Typography>
                <Typography className={classes.text}>Ligne {Ligne}</Typography>
                <Typography className={classes.text}>PK {PK}</Typography>
            </div>
            <Grid container className={classes.buttons_container}>
                <Grid item xs={6} onClick={onItinary} className={classes.left_button}>
                    <LocationOn className={classes.button_icon} />
                    <Typography className={classes.button_text}>Itinéraire</Typography>
                </Grid>
                <Grid item xs={6} onClick={onDownloadPDF} className={classes.right_button}>
                    {downloadLoading ?
                        <CircularProgress />
                    :
                        <>
                            <GetApp className={classes.button_icon} />
                            <Typography className={classes.button_text}>Fichier PDF</Typography>
                        </>
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default MarkerPopup;
