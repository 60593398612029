import React from 'react';
import mapboxgl from 'mapbox-gl';
import ReactDOM from 'react-dom';
import MarkerPopup from '../MarkerPopup';

// https://docs.mapbox.com/mapbox-gl-js/api/events/
const EventsListener = (map) => {
    const onClustersClick = (event) => {
        const features = map.queryRenderedFeatures((event.point), { layers: ['clusters'] });
        const clusterId = features[0].properties.cluster_id;
        map.getSource('points').getClusterExpansionZoom((clusterId), (err, zoom) => {
            if (err) return;
            map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom
            });
        });
    }

    const onPointClick = (event) => {
        const coordinates = event.features[0].geometry.coordinates.slice();
     
        /*
        **	Ensure that if the map is zoomed out such that
        **	multiple copies of the feature are visible, the
        **	popup appears over the copy being pointed to.
        */
        while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const Popup = document.createElement('div');
        ReactDOM.render(<MarkerPopup properties={event.features[0].properties} geometry={event.features[0].geometry} />, Popup);

        new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setMaxWidth('none')
        .setDOMContent(Popup)
        .addTo(map);
    }

    map.on('click', 'clusters', (event) => {
        event.preventDefault();
        onClustersClick(event);
    });

    map.on('touchstart', 'clusters', (event) => { // Handle mobile click
        event.preventDefault();
        onClustersClick(event);
    });
     
    map.on('click', 'unclustered-point', (event) => {
        event.preventDefault();
        onPointClick(event);
    });

    map.on('touchstart', 'unclustered-point', (event) => {  // Handle mobile click
        event.preventDefault();
        onPointClick(event);
    });

    map.on('mouseenter', 'unclustered-point', (event) => {
        event.preventDefault();
        map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', 'unclustered-point', (event) => {
        event.preventDefault();
        map.getCanvas().style.cursor = '';
    });
     
    map.on('mouseenter', 'clusters', (event) => {
        event.preventDefault();
        map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', 'clusters', (event) => {
        event.preventDefault();
        map.getCanvas().style.cursor = '';
    });
}

export default EventsListener;
