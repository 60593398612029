import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		width: 'auto'
	},
    container: {
		padding: '25px 75px 25px 75px',

		"@media (max-width: 1024px)": {
			padding: '20px 50px 20px 50px'
		},

		"@media (max-width: 768px)": {
			padding: '15px 25px 15px 25px'
		},

		"@media (max-width: 425px)": {
			padding: '10px 15px 10px 15px'
		}
    },
	title: {
		fontWeight: 'bold',
		fontSize: 15,

		"@media (max-width: 768px)": {
			fontSize: 12.5
		},

		"@media (max-width: 425px)": {
			fontSize: 10
		}
	},
	text: {
		color: '#BFBFBF',
		fontWeight: 'bold',
		fontSize: 15,

		"@media (max-width: 768px)": {
			fontSize: 12.5
		},

		"@media (max-width: 425px)": {
			fontSize: 10
		}
	},
	buttons_container: {
		boxShadow: '0px -1px 2px #BFBFBF'
	},
	left_button: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '15px 0px 15px 0px',
		border: 0,
		borderRightWidth: 1,
		borderStyle: 'solid',
		borderColor: '#BFBFBF',
		borderRadius: 0,
		cursor: 'pointer',

		'&:hover': {
			backgroundColor: '#F3F3F3'
		}
	},
	right_button: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '15px 0px 15px 0px',
		border: 0,
		borderStyle: 'solid',
		borderRadius: 0,
		cursor: 'pointer',

		'&:hover': {
			backgroundColor: '#F3F3F3'
		}
	},
	button_icon: {
		color: '#5677DB',
		fontSize: 20,
		marginRight: 5,

		"@media (max-width: 768px)": {
			fontSize: 15,
		},

		"@media (max-width: 425px)": {
			fontSize: 10
		}
	},
	button_text: {
		color: '#5677DB',
		fontSize: 15,

		"@media (max-width: 768px)": {
			fontSize: 12.5
		},

		"@media (max-width: 425px)": {
			fontSize: 10
		}
	}
}));

export default useStyles;
