export const pointInfoInitialState = {
	point_form: {
		material: '',
		native_proj: '',
		line: '',
		native_east: null,
		kp: null,
		native_north: null,
		is_inside_sncf: '',
		native_vrs: '',
		track: '',
		native_alti: null,
		status: '',
		computed_at: '',
		statuted_at: '',
		is_published: false
	}
}

const pointInfoReducer = (state, action) => {
	switch (action.type) {
		case "SET_POINT_FORM": {
			if (!action.point_form) return { ...state, point_form: pointInfoInitialState.point_form }
			const {
				material,
				native_proj,
				line,
				native_east,
				kp,
				native_north,
				is_inside_sncf,
				native_vrs,
				track,
				native_alti,
				status,
				computed_at,
				statuted_at,
				is_published,
			} = action.point_form;
			const current = state.point_form;
			return {
				...state,
				point_form: {
					material: material ? material : current.material,
					native_proj: native_proj ? native_proj : current.native_proj,
					line: line ? line : current.line,
					native_east: native_east ? native_east : current.native_east,
					kp: kp ? kp : current.kp,
					native_north: native_north ? native_north : current.native_north,
					is_inside_sncf: is_inside_sncf ? is_inside_sncf : current.is_inside_sncf,
					native_vrs: native_vrs ? native_vrs : current.native_vrs,
					track: track ? track : current.track,
					native_alti: native_alti ? native_alti : current.native_alti,
					status: status ? status : current.status,
					computed_at: computed_at ? computed_at : current.computed_at,
					statuted_at: statuted_at ? statuted_at : current.statuted_at,
					is_published
				}
			};
		}
		default:
			return state;
	}
};

export default pointInfoReducer;
