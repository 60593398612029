import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    login_container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075),0 0 10px gray'
    },
    logo: {
        position: 'absolute',
        width: 125,
        height: 125,
        marginTop: -100,

        "@media (max-width: 1024px)": {
            width: 100,
            height: 100,
            marginTop: -75
		},

		"@media (max-width: 768px)": {
            width: 75,
            height: 75,
            marginTop: -50
        }
    },
    title: {
        fontWeight: 'bold',
        marginTop: 50,
        marginBottom: 25
    },
    input_base: {
		margin: '10px 100px 10px 100px',
        padding: 10,
        width: 400,
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.ghost}`,

		'&:hover': {
			boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.blue_flash}`,
        },
        
        "@media (max-width: 1024px)": {
            width: 300,
            margin: '10px 75px 10px 75px'
        },

        "@media (max-width: 768px)": {
            width: 200,
            margin: '10px 50px 10px 50px'
        }
	},
	input_base_focus: {
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.blue_flash}`,
	},
	input_base_error: {
		margin: '10px 100px 10px 100px',
        padding: 10,
        width: 400,
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075),0 0 10px  red',
        
        '&:hover': {
			boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.blue_flash}`,
        },
		
		"@media (max-width: 1024px)": {
            width: 300,
            margin: '10px 75px 10px 75px'
        },

        "@media (max-width: 768px)": {
            width: 200,
            margin: '10px 50px 10px 50px'
        }
	},
	button: {
		backgroundColor: theme.palette.blue_flash,
		color: 'white',
		width: '48%',
		height: 50,
		textTransform: 'none',
		fontWeight: 'bold',
		marginTop: 25,
		marginBottom: 25
    },
    loading: {
		marginTop: 25,
		marginBottom: 25
	}
}));

export default useStyles;
