import React from 'react';
import { Link } from 'react-router-dom'

import useStyles from './styles/Layout';

const Layout = ({ children, brand }) => {

	const classes = useStyles();

	return (
		<div>
			<div className={classes.appbar}>
				<Link to='/'>
					<img src={brand} alt='' draggable={false} />
				</Link>
			</div>
			<div className={classes.bg} />
			<div className={classes.content}>
				{children}
			</div>
		</div>
	);
}

export default Layout;
