// https://docs.mapbox.com/mapbox-gl-js/example/geojson-markers/
const LoadSources = (map, geojson) => {
    map.addSource('points', {
        type: 'geojson',
        data: geojson,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 20 // Radius of each cluster when clustering points (defaults to 50)
    });
}

export default LoadSources;
