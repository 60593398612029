import React, { useState, useContext } from 'react';
import { Typography, Grid, Button, Modal, Backdrop, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { updatePoint } from '../../../api/PUT';

import CombinedContext from '../../../contexts/CombinedContext';

import useStyles from './styles/SaveModal';

const SaveModal = ({ open, setOpen, id, form }) => {

	const classes = useStyles();

	const [, dispatch] = useContext(CombinedContext);
	
	const [saveLoading, setSaveLoading] = useState(false);

	const onCloseModal = () => {
		setOpen(false);
	}
	
	const onSave = async () => {
		setSaveLoading(true);
		const res = await updatePoint(id, form);
		setSaveLoading(false);
		if (!res)
			return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Erreur inconnue, vérifier votre connexion internet', severity: 'error' });
		switch (res.status) {
			case 201:
				onCloseModal();
				return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Le point a bien été modifié', severity: 'success' });
			case 401:
				return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Action non autorisée', severity: 'error' });
			case 404:
				return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Serveur introuvable', severity: 'error' });
			default:
				return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Erreur inconnue, vérifier votre connexion internet', severity: 'error' });
		}
	}

	return (
		<Modal
			onClose={onCloseModal}
			open={open}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
			className={classes.modal}
		>
			<div className={classes.modal_root}>
				<div className={classes.modal_content_container}>
					<Typography className={classes.title}>ÊTES-VOUS SÛR DE VOULOIR MODIFIER LES INFORMATIONS DE CE POINT ?</Typography>
					<Grid container>
						<Grid item xs={6}>
							<Button className={clsx(classes.button, classes.cancel_button)} onClick={() => setOpen(false)}>Annuler</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								onClick={onSave}
								className={clsx(classes.button, classes.save_button)}
								disabled={saveLoading}
							>
								{saveLoading ? <CircularProgress /> : "Confirmer"}
							</Button>
						</Grid>
					</Grid>
				</div>
			</div>
		</Modal>
	);
}

export default SaveModal;
