import React, { useState, useContext } from 'react';
import { Typography, Grid, Button, Modal, Backdrop, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { deletePoint } from '../../../api/DELETE';

import CombinedContext from '../../../contexts/CombinedContext';

import useStyles from './styles/DeletePointModal';

const DeletePointModal = ({ open, setOpen, id, history }) => {

	const classes = useStyles();

	const [, dispatch] = useContext(CombinedContext);

	const [deleteLoading, setDeleteLoading] = useState(false);
	
	const onToggleModal = () => {
		setOpen(false);
	}
	
	const onDelete = async () => {
		setDeleteLoading(true);
		const res = await deletePoint(id);
		setDeleteLoading(false);
		if (!res)
			return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Erreur inconnue, vérifier votre connexion internet', severity: 'error' });
		switch (res.status) {
			case 204:
				dispatch({ type: 'ACTIVE_SNACKBAR', message: `Le point ${id} a bien été supprimé`, severity: 'success', duration: 5000 });
				return history.push({ pathname: '/admin-geo/home' })
			case 401:
				return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Action non autorisée', severity: 'error' });
			case 404:
				return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Serveur introuvable', severity: 'error' });
			default:
				return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Erreur inconnue, vérifier votre connexion internet', severity: 'error' });
		}
	}

	return (
		<Modal
			onClose={onToggleModal}
			open={open}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
			className={classes.modal}
		>
			<div className={classes.modal_root}>
				<div className={classes.modal_content_container}>
					<Typography className={classes.title}>ÊTES-VOUS SÛR DE VOULOIR SUPPRIMER CE POINT ?</Typography>
					<Grid container>
						<Grid item xs={6}>
							<Button className={clsx(classes.button, classes.cancel_button)} onClick={() => setOpen(false)}>Annuler</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								onClick={onDelete}
								className={clsx(classes.button, classes.delete_button)}
								disabled={deleteLoading}
							>
								{deleteLoading ? <CircularProgress /> : "Supprimer ce point"}
							</Button>
						</Grid>
					</Grid>
				</div>
			</div>
		</Modal>
	);
}

export default DeletePointModal;
