export const snackbarInitialState = {
    message: '',
    isSnackbarOpened: false,
    severity: '',
    duration: 3000
}

const snackbarReducer = (state, action) => {
    switch (action.type) {
        case "CLOSE_SNACKBAR": {
            return { ...state, isSnackbarOpened: false, severity: '', duration: 3000 };
        }
        case "ACTIVE_SNACKBAR": {
            const { message, severity, duration } = action;
            return { ...state, message, isSnackbarOpened: true, severity, duration };
        }
        default:
            return state;
    }
};

export default snackbarReducer;
