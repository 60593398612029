import React, { useState, useContext } from 'react';
import { Typography, Modal, Backdrop, CircularProgress } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';

import { getAllPoints } from '../../api/GET';
import { createPoints } from '../../api/POST';

import CombinedContext from '../../contexts/CombinedContext';

import useStyles from './styles/AddPointsModal';

const AddPointsModal = ({ open, setOpen }) => {

    const classes = useStyles();

    const [, dispatch] = useContext(CombinedContext);

    const [loading, setLoading] = useState(false);
    const [archive, setArchive] = useState(null);

    const onToggleModal = () => {
		setOpen(false);
    }
    
    const onFilesChange = (event) => {
        const file = event.target.files[0];
        if (file.size > 100000000) { // 100 mb
            return dispatch({
                type: 'ACTIVE_SNACKBAR',
                message: "La taille de l'archive est trop grande pour être importée",
                severity: 'error',
                duration: 5000
            });
        } else if (file.type !== 'application/zip' && file.type !== 'application/x-zip-compressed') {
            return dispatch({
                type: 'ACTIVE_SNACKBAR',
                message: "Erreur de format, merci d'importer uniquement des .zip",
                severity: 'error',
                duration: 5000
            });
        }
        setArchive(file);
    }

    const onSubmit = async () => {
        if (archive) {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', archive);
            const res = await createPoints(formData);
            if (!res)
                return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Erreur inconnue, vérifiez votre connexion internet', severity: 'error' });
            switch (res?.status) {
                case 201:
                    const resu = await getAllPoints();
                    if (resu)
                        dispatch({ type: "SET_ADMIN_POINTS", points: resu })
                    dispatch({
                        type: 'ACTIVE_SNACKBAR',
                        message: "Les points ont bien été ajoutés.",
                        severity: 'success',
                        duration: 5000
                    });
                    break;
                case 401:
                    dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Action non autorisée', severity: 'error' });
                    break;
                case 404:
                    dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Serveur introuvable', severity: 'error' });
                    break;
                case 500:
                    dispatch({
                        type: 'ACTIVE_SNACKBAR',
                        message: "Les points n'ont pas pu être ajoutés. Vérifiez qu'un point n'existe pas déjà",
                        severity: 'error',
                        duration: 5000
                    });
                    break;
                default:
                    dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Erreur inconnue, vérifiez votre connexion internet', severity: 'error' });
                    break;
            }
			setLoading(false);
			setArchive(null);
        }
    }

    return (
        <Modal
            onClose={onToggleModal}
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
            className={classes.modal}
        >
            <div className={classes.modal_root}>
                <HighlightOff className={classes.modal_close_button} onClick={onToggleModal} />
                <div className={classes.modal_content_container}>
                    <Typography className={classes.title}>AJOUTER DES POINTS PAR LOT</Typography>
                    <div className={classes.root_dropZone}>
                        <input
                            className={classes.dropzone_input}
                            name="companie_logo"
                            id="import-measurement-file-input"
                            type="file"
                            accept='.zip'
                            onChange={onFilesChange}
                            onClick={(event) => event.target.value = ''}
                        />
                        <div className={classes.input_dropZone}>
                            <Typography className={classes.dropzone_title}>GLISSEZ L'ARCHIVE ICI</Typography>
                            {archive && <Typography className={classes.dropzone_title}>{archive.name}</Typography>}
                        </div>
                    </div>
                    {loading ?
                        <CircularProgress className={classes.loading} />
                    :
                        <button onClick={onSubmit} className={archive ? classes.button : classes.disabled_button}>Importer</button>
                    }
                </div>
            </div>
        </Modal>
    );
}

export default AddPointsModal;

