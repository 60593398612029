import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '60%',
		height: '80%',
        backgroundColor: 'white',
		boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075),0 0 10px gray',
        padding: 25,
        overflowY: 'auto',
        overflowX: 'hidden',

        "@media (max-width: 1024px)": {
            width: '65%'
		},

		"@media (max-width: 768px)": {
            width: '70%'
        },
        
        "@media (max-width: 425px)": {
            width: '75%'
		}
    },
    return: {
        fontWeight: 'bold',
        color: theme.palette.ghost,
        cursor: 'pointer',

        '&:hover': {
            color: 'gray'
        }
    },
    data_loading_container: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content_container: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 0px 0px 0px'
    },
    title_container: {
        padding: '10px 0px 10px 0px',
        backgroundColor: theme.palette.blue_flash,
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 15
    },
    publish_root: {
        width: 35.5,
        height: 16,
        padding: 0,
        margin: '0px 20px 0px 10px',
    },
    publish_switchBase: {
        padding: 2
    },
    publish_thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none'
    },
    label: {
        fontSize: 12.5,
        color: 'black',
        fontWeight: 'bold'
    },
    textfield_text: {
        fontSize: 12.5,
        color: 'gray'
    },
    info_container: {
        marginTop: 25
    },
    grid_item_container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    button_container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    button: {
        backgroundColor: theme.palette.blue_flash,
        fontSize: 12.5,
		color: 'white',
		width: '25%',
		height: 50,
		textTransform: 'none',
		fontWeight: 'bold',
        margin: '25px 25px 0px 25px',
        
        "@media (max-width: 1024px)": {
            width: '30%',
            margin: '20px 20px 0px 20px'
		},

		"@media (max-width: 768px)": {
            width: '35%',
            margin: '15px 15px 0px 15px'
        },
        
        "@media (max-width: 425px)": {
            width: '50%',
            margin: '10px 10px 0px 10px'
		}
    },
    select: {
        width: '100%',
        color: 'gray'
    },
    textfield_container: {
        width: '100%'
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    switch_text_color: {
        color: 'white'
    },
    grid_cell: {
        width: '25%'
    },
    select_icon: {
        display: 'none'
    }
}));

export default useStyles;
