import React, { useState } from 'react';
import { Typography, Modal, Backdrop, Tooltip } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import { IoMdHelp } from 'react-icons/io';

import useStyles from './styles/Help';

const Help = () => {

    const classes = useStyles();

    const [modal, setModal] = useState(false);

    const onToggleModal = () => {
		setModal(!modal);
	}

    return (
        <div>
            <Tooltip title="Aide" placement="right">
				<div id="Help" className={classes.control_button_container} onClick={onToggleModal}>
					<IoMdHelp size={18} className={classes.controls_icon} />
				</div>
			</Tooltip>
            <Modal
                onClose={onToggleModal}
                open={modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
                className={classes.modal}
            >
                <div className={classes.modal_root}>
                    <HighlightOff className={classes.modal_close_button} onClick={onToggleModal} />
                    <div className={classes.horizontal_center}>
                        <Typography className={classes.title}>AIDE EN LIGNE DU PORTAIL SMARTTOPO</Typography>
                    </div>
                    <div className={classes.modal_content_container}>
                        <Typography className={classes.text}>
                            SmartTopo est un portail de visualisation, de recherche et téléchargement des points de canevas topographiques principaux, créés pour le compte de SNCF Réseau.
                        </Typography>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Help;
