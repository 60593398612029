import { axiosPOST } from '../axios/axios';

const domain = process.env.REACT_APP_SERVEUR_ENDPOINT;

export const sendEmail = (data) => {
	const url = `${domain}/api/email/new`;
	return axiosPOST(url, data);
}

export const createPoints = (data) => {
	const url = `${domain}/api/points`;
	return axiosPOST(url, data);
}


export const getExportPoints = (ids, export_types) => {
    const url = `${domain}/api/points`;
    return axiosPOST(url, {id: ids, export: export_types}, {responseType: 'blob'});
}

export const login = (data) => {
	const url = `${domain}/api/auth/login`;
	return axiosPOST(url, data, {
		withCredentials: true,
		headers: {
			crossDomain: true,
			'Content-Type': 'application/json'
		}
	});
}

export const logout = (data) => {
	const url = `${domain}/api/auth/logout`;
	return axiosPOST(url, data);
}
