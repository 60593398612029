import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
		fontWeight: 'bold',
		fontSize: 15
    },
    unselected_checkbox: {
        opacity: 0.5
    },
	dialog_header_container: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	dialog_close_button: {
		cursor: 'pointer',
		color: theme.palette.ghost,

		'&:hover': {
			color: 'gray'
		}
	},
	dialog_content_root: {
		padding: '0px 50px 50px 50px',
		overflowX: 'hidden',

		"@media (max-width: 768px)": {
			padding: '0px 40px 40px 40px'
		},

		"@media (max-width: 425px)": {
			padding: '0px 30px 30px 30px'
		},
	},
	export_button_container: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 15
	},
	export_button: {
		backgroundColor: theme.palette.blue_flash,
		color: '#fff',
		padding: '15px 0px 15px 0px',
		fontWeight: 'bold',
		fontSize: 20,
		textTransform: 'none',
		width: '75%',
		outline: 0,
		border: 0,
		borderRadius: 5,
		cursor: 'pointer',

		'&:hover': {
			opacity: 0.5
		}
	},
	disabled_export_button: {
		backgroundColor: theme.palette.ghost,
		color: '#fff',
		padding: '15px 0px 15px 0px',
		fontWeight: 'bold',
		fontSize: 20,
		textTransform: 'none',
		width: '75%',
		outline: 0,
		border: 0,
		borderRadius: 5,
		cursor: 'not-allowed'
	}
}));

export default useStyles;
