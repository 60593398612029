
/*
	La fonction addControl de mapboxgl nécessite d'envoyer une class et non un composant fonctionnel
	Exemple:
		map.addControl(new CustomControl({ element_id: "div_id" }), 'top-right')

	Pour afficher, il faut que l'id soit la même que element_id:
		<div id="div_id"></div>
*/

// https://docs.mapbox.com/mapbox-gl-js/api/markers/#icontrol
export default class CustomControl {
	constructor(props) {
		this.element_id = props.element_id;
	}

	onAdd(map) {
		this.map = map;
		this.container = document.createElement('div');
		this.container.className = 'mapboxgl-ctrl';
	
		const CustomControl = document.getElementById(this.element_id)
		this.container.appendChild(CustomControl);
		return this.container;
	}

	onRemove() {
		this.container.parentNode.removeChild(this.container);
		this.map = undefined;
	}
}
