import React from 'react';
import { Typography, Grid, InputBase, Select, MenuItem, Radio } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import useStyles from './styles/SearchByRailway';

const SearchByRailway = ({ radios, setRadios, searchForm, setSearchForm }) => {

    const classes = useStyles();

    const onToggleRadio = (event) => {
		setRadios({ ...radios, [`${event.target.value}`]: !radios[`${event.target.value}`] });
    }

    const onSearchValueChange = (event) => {
		setSearchForm({ ...searchForm, [`${event.target.name}`]: event.target.value });
	}

    return (
        <div>
            <Grid container className={classes.drawer_table_row}>
                <Grid item xs={1} className={classes.drawer_table_column}>
                    <Radio
                        value="sncf_line_number"
                        color="primary"
                        className={classes.radio}
                        checked={radios.sncf_line_number} onClick={onToggleRadio}
                        icon={<RadioButtonUncheckedIcon className={classes.radio_size} />}
                        checkedIcon={<RadioButtonCheckedIcon className={classes.radio_size} />}
                    />
                </Grid>
                <Grid item xs={7}>
                    <Typography className={classes.drawer_text}>N° de ligne SNCF</Typography>
                </Grid>
                <Grid item xs={4}>
                    <InputBase
                        name="sncf_line_number"
                        placeholder="Remplir ici..."
                        className={classes.drawer_text}
                        onChange={onSearchValueChange}
                        value={searchForm.sncf_line_number}
                        type="number"
                    />
                </Grid>
            </Grid>
            
            <Grid container className={classes.drawer_table_row}>
                <Grid item xs={1} className={classes.drawer_table_column}>
                    <Radio
                        value="pk_above"
                        color="primary"
                        className={classes.radio}
                        checked={radios.pk_above} onClick={onToggleRadio}
                        icon={<RadioButtonUncheckedIcon className={classes.radio_size} />}
                        checkedIcon={<RadioButtonCheckedIcon className={classes.radio_size} />}
                    />
                </Grid>
                <Grid item xs={7}>
                    <Typography className={classes.drawer_text}>PK au-dessus de</Typography>
                </Grid>
                <Grid item xs={4}>
                    <InputBase
                        name="pk_above"
                        placeholder="Remplir ici..."
                        className={classes.drawer_text}
                        onChange={onSearchValueChange}
                        value={searchForm.pk_above}
                        type="number"
                    />
                </Grid>
            </Grid>

            <Grid container className={classes.drawer_table_row}>
                <Grid item xs={1} className={classes.drawer_table_column}>
                    <Radio
                        value="pk_below"
                        color="primary"
                        className={classes.radio}
                        checked={radios.pk_below} onClick={onToggleRadio}
                        icon={<RadioButtonUncheckedIcon className={classes.radio_size} />}
                        checkedIcon={<RadioButtonCheckedIcon className={classes.radio_size} />}
                    />
                </Grid>
                <Grid item xs={7}>
                    <Typography className={classes.drawer_text}>PK en-dessous de</Typography>
                </Grid>
                <Grid item xs={4}>
                    <InputBase
                        name="pk_below"
                        placeholder="Remplir ici..."
                        className={classes.drawer_text}
                        onChange={onSearchValueChange}
                        value={searchForm.pk_below}
                        type="number"
                    />
                </Grid>
            </Grid>

            <Grid container className={classes.drawer_table_row}>
                <Grid item xs={1} className={classes.drawer_table_column}>
                    <Radio
                        value="is_inside_sncf"
                        color="primary"
                        className={classes.radio}
                        checked={radios.is_inside_sncf} onClick={onToggleRadio}
                        icon={<RadioButtonUncheckedIcon className={classes.radio_size} />}
                        checkedIcon={<RadioButtonCheckedIcon className={classes.radio_size} />}
                    />
                </Grid>
                <Grid item xs={7}>
                    <Typography className={classes.drawer_text}>En emprises SNCF</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Select
                        name="is_inside_sncf"
                        value={searchForm.is_inside_sncf}
                        disableUnderline
                        className={classes.drawer_select}
                        onChange={onSearchValueChange}
                    >
                        <MenuItem className={classes.drawer_text} value="Sélectionner" disabled>
                            <Typography className={classes.drawer_text}>Sélectionner</Typography>
                        </MenuItem>
                        <MenuItem className={classes.drawer_text} value="oui">
                            <Typography className={classes.drawer_text}>Oui</Typography>
                        </MenuItem>
                        <MenuItem className={classes.drawer_text} value="non">
                            <Typography className={classes.drawer_text}>Non</Typography>
                        </MenuItem>
                    </Select>
                </Grid>
            </Grid>

            <Grid container className={classes.drawer_table_row}>
                <Grid item xs={1} className={classes.drawer_table_column}>
                    <Radio
                        value="track"
                        color="primary"
                        className={classes.radio}
                        checked={radios.track} onClick={onToggleRadio}
                        icon={<RadioButtonUncheckedIcon className={classes.radio_size} />}
                        checkedIcon={<RadioButtonCheckedIcon className={classes.radio_size} />}
                    />
                </Grid>
                <Grid item xs={7}>
                    <Typography className={classes.drawer_text}>N° voie adjacente</Typography>
                </Grid>
                <Grid item xs={4}>
                    <InputBase
                        name="track"
                        placeholder="Remplir ici..."
                        className={classes.drawer_text}
                        onChange={onSearchValueChange}
                        value={searchForm.track}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default SearchByRailway;
