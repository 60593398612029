import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    appbar: {
        position: 'absolute',
        backgroundColor: 'white',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 0px 10px gray',
        justifyContent: 'space-between',
        paddingLeft: 25,
		paddingRight: 10,
		'& img' :{
			width: '200px'
		},

        "@media (max-width: 1024px)": {
            paddingLeft: 20
		},

		"@media (max-width: 768px)": {
            paddingLeft: 15
        },
        
        "@media (max-width: 425px)": {
            paddingLeft: 10
		}
    },
    bg: {
        backgroundImage: 'linear-gradient(-60deg, #95B4D3 50%, #fff 50%)',
        bottom: 0,
        left: '-50%',
        position: 'fixed',
        right: '-50%',
        top: 0
    },
    content: {
        position: 'absolute',
        top: 50,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'auto'
    }
}));

export default useStyles;
