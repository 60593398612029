import axios from 'axios';

/** 
 * @description Allow to fetch data
 * 
 * @param {string} url url to get
 * @return {array} data 
 */

const axiosGET = async (url, responseType) => {
    if (typeof url !== "string") {
        return console.error("axiosGET : it's not an string !");
    }
    try {
        const data = await axios.get(url, { responseType: responseType ? responseType : null });
        return data.data;
    } catch (error) {
        console.error(error.response);
        return error.response;
    }
};


/** 
 * @description Allow to post data
 * 
 * @param {string} url url to post
 * @param {object} data data to post
 * @return {any} result 
 */

const axiosPOST = async (url, data, option = {}) => {
    if (typeof url !== "string") {
        return console.error("axiosPOST : it's not an string !");
    }
    if (typeof data !== "object") {
        return console.error("axiosPOST : data must be an object");
    }
    try {
        const result = await axios.post(url, data, option);
        return result;
    } catch (error) {
        console.error(error.response);
        return error.response;
    }
};


/** 
 * @description Allow to post data
 * 
 * @param {string} url url to post
 * @param {object} data data to post
 * @return {any} result 
 */

const axiosPUT = async (url, data) => {
    if (typeof url !== "string") {
        return console.error("axiosPUT : url must be an string !");
    }
    if (typeof data !== "object") {
        return console.error("axiosPUT : data must be an object !");
    }
    try {
        const result = await axios.put(url, data);
        return result;
    } catch (error) {
        console.error(error.response);
        return error.response;
    }
};

/** 
 * @description Allow to delete data
 * 
 * @param {string} url url to delete
 * @return {any} result 
 */

const axiosDELETE = async (url) => {
    if (typeof url !== "string") {
        return console.error("axiosDELETE : it's not an string !");
    }
    try {
        const result = await axios.delete(url, {});
        return result;
    } catch (error) {
        console.error(error.response);
        return error.response;
    }
};

export { axiosGET, axiosPOST, axiosPUT, axiosDELETE };
