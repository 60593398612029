import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 'bold',
		marginBottom: 25,
        fontSize: 15,
        
        "@media (max-width: 1024px)": {
			fontSize: 12.5
		},
		
		"@media (max-width: 768px)": {
			fontSize: 10
		}
	},
    button: {
        color: 'white',
		width: 300,
		height: 50,
		textTransform: 'none',
        fontWeight: 'bold',
        fontSize: 15,
        
        "@media (max-width: 1024px)": {
            width: 250,
            fontSize: 12.5,
		},
		
		"@media (max-width: 768px)": {
            width: 200,
            fontSize: 10
		},
		
		"@media (max-width: 425px)": {
            width: 125
		}
    },
    cancel_button: {
        backgroundColor: theme.palette.blue_flash,
        marginRight: 10
	},
	delete_button: {
        backgroundColor: '#EE2C47',
        marginLeft: 10
	},
	modal: {
		display:'flex',
		alignItems:'center',
		justifyContent:'center'
	},
	modal_root: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'white',
		outline: 'none',
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px gray`,
		overflow: 'auto'
	},
	modal_content_container: {
        display: 'flex',
        flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 75,

		"@media (max-width: 1024px)": {
			padding: 50
		},
		
		"@media (max-width: 768px)": {
			padding: 25
		},
		
		"@media (max-width: 425px)": {
			padding: 10
		}
	}
}))

export default useStyles;
