import React, { useEffect, useContext, useState } from 'react';
import { CircularProgress } from '@material-ui/core';

import Mapbox from './Mapbox/Mapbox';
import TopLeftControls from './Mapbox/TopLeftControls/TopLeftControls';
import BottomLeftControls from './Mapbox/BottomLeftControls/BottomLeftControls';
import { getPublishedPoints } from '../../api/GET';

import CombinedContext from '../../contexts/CombinedContext';

import useStyles from './styles/Home';

const Home = (props) => {

	const classes = useStyles();

	const [state, dispatch] = useContext(CombinedContext);

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetch = async () => {
			const geojson = await getPublishedPoints();
			if (geojson)
				dispatch({ type: 'SET_MAPBOX_GEOJSON', geojson });
			setIsLoading(false);
		}
		
		fetch();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<div className={classes.appbar}>
				<img src={'assets/images/SmartTopo_logo.svg'} alt='SmartTopo' draggable={false} />
				<div id="geocoder" />
			</div>
			{!isLoading ?
				<div>
					<Mapbox geojson={state.mapbox.geojson} urlParams={props.location.search} />
					<TopLeftControls />
					<BottomLeftControls />
				</div>
			:
				<div className={classes.loading_root}>
					<div className={classes.loading_container}>
						<CircularProgress />
					</div>
				</div>
			}
		</div>
	);
}

export default Home;
