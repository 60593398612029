import React from 'react';

import Search from './Search/Search';

const TopLeftControls = () => {
	return (
		<div>
			<Search />
		</div>
	)
}

export default TopLeftControls;
