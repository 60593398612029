import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	control_button_container: {
		backgroundColor: 'white',
		cursor: 'pointer',
		width: 27,
		height: 27,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 5,
		border: '2px solid rgba(0, 0, 0, 0.2)',
		boxShadow: '2px 2px 2px gray',

		'&:hover': {
			backgroundColor: '#F2F2F2'
		}
	},
	controls_icon: {
		color: theme.palette.dark_gray
	},
	modal_close_button: {
		position: 'absolute',
		top: 10,
		right: 10,
		cursor: 'pointer',
		color: theme.palette.ghost,
		height: 50,
		width: 50,
		alignSelf: 'flex-end',

		'&:hover': {
			color: 'gray'
		},

		"@media (max-width: 1024px)": {
			top: 7.5,
			right: 7.5,
			height: 45,
			width: 45
		},
		
		"@media (max-width: 768px)": {
			top: 5,
			right: 5,
			height: 40,
			width: 40
		},
		
		"@media (max-width: 425px)": {
			top: 2.5,
			right: 2.5,
			height: 35,
			width: 35
		}
	},
	title: {
		fontWeight: 'bold',
		margin: '30px 0px 20px 0px',
		fontSize: 15,

		"@media (max-width: 1024px)": {
			margin: '25px 0px 15px 0px'
		},
		
		"@media (max-width: 768px)": {
			margin: '20px 0px 10px 0px'
		},
		
		"@media (max-width: 425px)": {
			margin: '15px 0px 5px 0px'
		}
	},
	input_base: {
		marginTop: 10,
		marginBottom: 10,
		width: '100%',
		padding: 10,
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.ghost}`,

		'&:hover': {
			boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.blue_flash}`,
		},

		"@media (max-width: 768px)": {
			padding: 5
		}
	},
	input_base_focus: {
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.blue_flash}`,
	},
	input_base_error: {
		marginTop: 10,
		marginBottom: 10,
		width: '100%',
		padding: 10,
		boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075),0 0 10px  red',
		
		"@media (max-width: 768px)": {
			padding: 5
		}
	},
	button: {
		backgroundColor: theme.palette.blue_flash,
		color: 'white',
		width: '48%',
		height: 50,
		textTransform: 'none',
		fontWeight: 'bold',
		marginTop: 25,
		marginBottom: 25
	},
	loading: {
		marginTop: 25,
		marginBottom: 25
	},
	success_button: {
		backgroundColor: '#36B27A',
		color: 'white',
		width: '100%',
		height: 50,
		textTransform: 'none',
		fontWeight: 'bold',
		marginTop: 25
	},
	modal: {
		display:'flex',
		alignItems:'center',
		justifyContent:'center'
	},
	modal_root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'white',
		outline: 'none',
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px gray`,
		height: '60%',
		width: '50%',
		overflow: 'auto',

		"@media (max-width: 1024px)": {
			width: '65%'
		},
		
		"@media (max-width: 768px)": {
			width: '80%'
		},
		
		"@media (max-width: 425px)": {
			width: '95%'
		}
	},
	modal_content_container: {
		alignSelf: 'center',
		justifySelf: 'center',
		padding: '0px 150px 0px 150px',

		"@media (max-width: 1024px)": {
			padding: '0px 100px 0px 100px'
		},
		
		"@media (max-width: 768px)": {
			padding: '0px 50px 0px 50px'
		},
		
		"@media (max-width: 425px)": {
			padding: '0px 25px 0px 25px'
		}
	},
	modal_response_main_container: {
		display:'flex',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '0px 25px 0px 25px'
	},
	horizontal_center: {
		display: 'flex',
		justifyContent: 'center'
	},
	modal_response_container: {
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'center'
	},
	response_message_container: {
		display: 'flex',
		justifyContent: 'center',
		padding: 10,
		borderRadius: '0px 5px 5px 0px'
	},
	response_icon_container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 10,
		borderRadius: '5px 0px 0px 5px'
	},
	success_message: {
		backgroundColor: '#60B07E'
	},
	success_icon: {
		backgroundColor: '#467F59'
	},
	failure_message: {
		backgroundColor: '#D9424C'
	},
	failure_icon: {
		backgroundColor: '#812C34'
	},
	response_content_color: {
		color: 'white'
	},
	captcha_container: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: 5
	},
	captcha_input: {
		width: '100%',
		marginLeft: 10,
		padding: '0px 10px 0px 10px',
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.ghost}`,

		'&:hover': {
			boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px ${theme.palette.blue_flash}`,
		},

		"@media (max-width: 768px)": {
			padding: '0px 5px 0px 5px'
		}
	},
	captcha_error: {
		width: '100%',
		marginLeft: 10,
		padding: '0px 10px 0px 10px',
		boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075),0 0 10px  red',
		
		"@media (max-width: 768px)": {
			padding: '0px 5px 0px 5px'
		}
	},
}))

export default useStyles;
