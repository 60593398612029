export const adminDataInitialState = {
    points: []
}

const adminDateReducer = (state, action) => {
    switch (action.type) {
        case "ADMIN_CLEAR_ALL": {
            return {
                points: []
            };
        }
        case "SET_ADMIN_POINTS": {
            const { points } = action;
            return { ...state, points };
        }
        default:
            return state;
    }
};

export default adminDateReducer;
