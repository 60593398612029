import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    draw_icon: {
		cursor: 'pointer',
		padding: 5,
		fontSize: 17.5,
		color: theme.palette.blue_flash,
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 5px ${theme.palette.ghost}`,

		'&:hover': {
			backgroundColor: '#F2F2F2'
		}
	},
	trash_icon: {
		cursor: 'pointer',
		marginLeft: 10,
		padding: 5,
		fontSize: 17.5,
		color: theme.palette.blue_flash,
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 5px ${theme.palette.ghost}`,

		'&:hover': {
			backgroundColor: '#F2F2F2'
		}
	}
}));

export default useStyles;
