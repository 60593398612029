import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    map_container: {
		position: 'absolute',
		top: 50,
		bottom: 0,
		left: 0,
		right: 0,
		overflow: 'hidden'
	}
}));

export default useStyles;
