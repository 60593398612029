// https://docs.mapbox.com/mapbox-gl-js/example/cluster/
const AddLayers = (map,) => {
    map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'points',
        filter: ['has', 'point_count'],
        paint: {
            'circle-color': '#5677DB',
            'circle-opacity': 0.5,
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                20, // Taille du cercle
                5, // Nombre de points nécessaire pour appliquer la taille au-dessus de cette ligne
                25, // Taille du cercle
                10, // Nombre de points nécessaire pour appliquer la taille au-dessus de cette ligne
                30 // Taille du cercle le plus grand
            ]
        }
    });
     
    map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'points',
        filter: ['has', 'point_count'],
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 20
        },
        paint: {
            'text-color': '#ffffff'
        }
    });

    map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'points',
        filter: ['!', ['has', 'point_count']],
        layout: {
            "icon-image": 'SmartTopoMarker'
        }
    });
}

export default AddLayers;
