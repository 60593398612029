import React, { useEffect, useContext } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { AiOutlineEdit } from 'react-icons/ai';

import CombinedContext from '../../../../../contexts/CombinedContext';

import useStyles from './styles/SearchByPolygons';

const SearchByPolygons = ({ draw, geojson, selectedPoints }) => {

	const classes = useStyles();

	const [, dispatch] = useContext(CombinedContext);

	useEffect(() => {
		if (draw) {
			const polygon = document.getElementById('draw_polygon');
			const trash = document.getElementById('delete_polygon');

			polygon.onclick = () => { // Voir le fichier src/components/Home/Mapbox/Mapbox.jsx pour la fonction d'ajout
				const polygons = draw.getAll();
				if (polygons.features.length > 0)
					return dispatch({ type: 'ACTIVE_SNACKBAR', message: "Vous ne pouvez dessiner qu'un seul polygone", severity: 'warning' });
				draw.changeMode('draw_polygon');
			}

			trash.onclick = () => {
				dispatch({ type: 'SET_MAPBOX_SELECTED_POINTS', selectedPoints: [] });
				dispatch({ type: 'SET_MAPBOX_DELETE_POLYGON', deletePolygon: true });
				draw.deleteAll();
			}
		}
	}, [draw, geojson, selectedPoints]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<AiOutlineEdit id="draw_polygon" className={classes.draw_icon} />
			<FaTrashAlt id="delete_polygon" className={classes.trash_icon} />
		</div>
	);
}

export default SearchByPolygons;
