import React, { useState, useEffect, useContext } from 'react';
import { Typography, InputBase, CircularProgress, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { Add, PowerSettingsNew } from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import { getAllPoints } from '../../api/GET';
import { logout } from '../../api/POST';

import Layout from './Layout';
import AddPointsModal from './AddPointsModal';

import CombinedContext from '../../contexts/CombinedContext';

import useStyles from './styles/Home';

const StyledTable = withStyles((theme) => ({
	stickyHeader: {
		'& .MuiTableHead-root': {
			backgroundColor: theme.palette.blue_flash,
		}
	},
}))(Table);

const Home = (props) => {

	const classes = useStyles();

	const [state, dispatch] = useContext(CombinedContext);

	const [addPointsModal, setAddPointsModal] = useState(false);
	const [search, setSearch] = useState('');
	const [filteredList, setFilteredList] = useState([]);
	const [page, setPage] = useState(1);
	const [getDataIsLoading, setGetDataIsLoading] = useState(false);
	const [logoutLoading, setLogoutLoading] = useState(false);
	const rowsPerPage = 10;

	useEffect(() => {
		const initData = async () => {
			setGetDataIsLoading(true);
			const res = await getAllPoints();
			if (res)
				dispatch({ type: "SET_ADMIN_POINTS", points: res });
			setGetDataIsLoading(false);
		}

		initData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setFilteredList(state.admin.points);
	}, [state.admin.points]);

	const onAddPoints = () => {
		setAddPointsModal(true);
	}

	const onSearch = (event) => {
		setSearch(event.target.value);
		const list = state.admin.points.filter((point) => (
			(point.point_id && point.point_id.toString().includes(event.target.value)) ||
			(point.line && point.line.toString().includes(event.target.value)) ||
			(point.kp && point.kp.toString().includes(event.target.value)) ||
			(point.track && point.track.toUpperCase().includes(event.target.value.toUpperCase())) ||
			(point.status && point.status.toUpperCase().includes(event.target.value.toUpperCase()))
		));
		setFilteredList(list);
	}

	const onDisconnect = async () => {
		setLogoutLoading(true);
		const formData = new FormData();
		const res = await logout(formData);
		setLogoutLoading(false);
		if (res && res.status === 200) {
			dispatch({ type: "CLEAN_ADMIN_ALL" });
			props.history.push({ pathname: '/admin-geo' });
		}
	}

	const onPaginationChange = (event, pageNumber) => {
		setPage(pageNumber);
	}

	const convertDateFormat = (date) => {
		const newDate = new Date(date);
		return `${newDate.getFullYear()}-${("0" + (newDate.getMonth() + 1)).slice(-2)}-${("0" + newDate.getDate()).slice(-2)}`;
	}

	const onRowClick = (row) => {
		props.history.push({ pathname: `/admin-geo/points/${row.point_id}` });
	}

	return (
		<Layout brand='../assets/images/SmartTopo_logo.svg'>
			<div className={classes.root}>
				<div className={classes.container}>
					<div className={classes.top_container}>
						<div className={classes.add_button_container} onClick={onAddPoints}>
							<div className={classes.add_button_icon_container}>
								<Add className={classes.add_icon} />
							</div>
							<div className={classes.add_button_text_container}>
								<Typography className={classes.add_text}>Ajouter des points par lot</Typography>
							</div>
						</div>
						<div className={classes.search_container}>
							<InputBase
								placeholder="Recherche..."
								value={search}
								onChange={onSearch}
								className={classes.input_base}
								classes={{ focused: classes.input_base_focus }}
								inputProps={{ maxLength: 100 }}
							/>
							{logoutLoading ?
								<CircularProgress />
								:
								<PowerSettingsNew className={classes.disconnect_icon} onClick={onDisconnect} />
							}
						</div>
					</div>

					{getDataIsLoading ?
						<div className={classes.table_align_center}>
							<CircularProgress />
						</div>
						:
						<div className={classes.table_container}>
							{filteredList.length === 0 ?
								<div className={classes.table_align_center}>
									<Typography>Aucune donnée</Typography>
								</div>
								:
								<TableContainer >
									<StyledTable className={classes.table} >
										<TableHead className={classes.table_head}>
											<TableRow className={classes.table_head_row}>
												<TableCell className={classes.table_head_cell}>Numéro</TableCell>
												<TableCell className={classes.table_head_cell}>Ajouté le</TableCell>
												<TableCell className={classes.table_head_cell}>Ligne</TableCell>
												<TableCell className={classes.table_head_cell}>PK</TableCell>
												<TableCell className={classes.table_head_cell}>En emprise</TableCell>
												<TableCell className={classes.table_head_cell}>Voie</TableCell>
												<TableCell className={classes.table_head_cell}>Statut</TableCell>
												<TableCell className={classes.table_head_cell}>Date de statut</TableCell>
												<TableCell className={classes.table_head_cell}>Calculé le</TableCell>
												<TableCell className={classes.table_head_cell}>Publié</TableCell>
											</TableRow>
										</TableHead>
										<TableBody >
												{filteredList.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((item, index) => (
													<TableRow key={index} classes={{ root: classes.table_body_row }} hover onClick={() => onRowClick(item)}>
														<TableCell className={classes.table_body_cell}>{item.point_id}</TableCell>
														<TableCell className={classes.table_body_cell}>{convertDateFormat(item.created_at)}</TableCell>
														<TableCell className={classes.table_body_cell}>{item.line}</TableCell>
														<TableCell className={classes.table_body_cell}>{item.kp.toFixed(3)}</TableCell>
														<TableCell className={classes.table_body_cell}>{item.is_inside_sncf ? 'Oui' : 'Non'}</TableCell>
														<TableCell className={classes.table_body_cell}>{item.track}</TableCell>
														<TableCell className={classes.table_body_cell}>{item.status}</TableCell>
														<TableCell className={classes.table_body_cell}>{convertDateFormat(item.statuted_at)}</TableCell>
														<TableCell className={classes.table_body_cell}>{convertDateFormat(item.computed_at)}</TableCell>
														<TableCell className={classes.table_body_cell}>{item.is_published ? 'Oui' : 'Non'}</TableCell>
													</TableRow>
												))}
										</TableBody>
									</StyledTable>
								</TableContainer>
							}
						</div>
					}
					<div className={classes.pagination_container}>
						<Pagination
							count={filteredList.length === 0 ? 1 : Math.ceil(parseFloat(filteredList.length / rowsPerPage))}
							variant="outlined"
							color="primary"
							showFirstButton
							showLastButton
							onChange={onPaginationChange}
						/>
					</div>
				</div>
			</div>

			<AddPointsModal open={addPointsModal} setOpen={setAddPointsModal} />
		</Layout>
	);
}

export default Home;
