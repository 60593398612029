import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    CircularProgress
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import { saveAs } from 'file-saver';

import { getExportPoints } from '../../../../api/POST';

import useStyles from './styles/ExportPointsModal';

const ExportPointsModal = ({ open, setOpen, selectedPoints }) => {

    const classes = useStyles();

    const [downloadLoading, setDownloadLoading] = useState(false);
    const [exportCheckbox, setExportCheckbox] = useState({
        csv: false,
        kmz: false,
        geojson: false,
        pdf: false
    });

    const onToggleModal = () => {
        setExportCheckbox({
            csv: false,
            kmz: false,
            geojson: false,
            pdf: false
        });
        setOpen(!open);
    }

    const onExportCheckboxChange = (event) => {
        setExportCheckbox({ ...exportCheckbox, [`${event.target.value}`]: !exportCheckbox[`${event.target.value}`] });
    }

    const onExport = async () => {
        setDownloadLoading(true);
        const { csv, kmz, geojson, pdf } = exportCheckbox;
        let export_types = `${csv ? 'csv,' : ''}${kmz ? 'kmz,' : ''}${geojson ? 'geojson,' : ''}${pdf ? 'pdf,' : ''}`;
        let points_id = '';
        for (let i = 0; i < selectedPoints.length; i++)
            points_id += `${selectedPoints[i].properties.ID},`;
        getExportPoints(points_id.substring(0, points_id.length - 1), export_types.substring(0, export_types.length - 1))
            .then((res) => {
                (res.data.type !== 'application/zip') ? saveAs(res.data, `points.${export_types.slice(0, export_types.length - 1)}`) : saveAs(res.data, 'points.zip');
            });
        setDownloadLoading(false);
    }

    return (
        <Dialog
            onClose={onToggleModal}
            open={open}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle>
                <div className={classes.dialog_header_container}>
                    <Close className={classes.dialog_close_button} onClick={onToggleModal} />
                </div>
            </DialogTitle>
            <DialogContent className={classes.dialog_content_root}>
                <Typography className={classes.title}>EXPORTER LES DONNÉES - {selectedPoints && selectedPoints.length} point(s) sélectionné(s)</Typography>
                <FormControl>
                    <FormGroup column='true'>
                        <FormControlLabel
                            value="csv"
                            className={clsx(classes.title, !exportCheckbox.csv && classes.unselected_checkbox)}
                            checked={exportCheckbox.csv}
                            onChange={onExportCheckboxChange}
                            control={<Checkbox color="default" />}
                            label="CSV"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="kmz"
                            className={clsx(classes.title, !exportCheckbox.kmz && classes.unselected_checkbox)}
                            checked={exportCheckbox.kmz}
                            onChange={onExportCheckboxChange}
                            control={<Checkbox color="default" />}
                            label="KMZ"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="geojson"
                            className={clsx(classes.title, !exportCheckbox.geojson && classes.unselected_checkbox)}
                            checked={exportCheckbox.geojson}
                            onChange={onExportCheckboxChange}
                            control={<Checkbox color="default" />}
                            label="GeoJSON"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="pdf"
                            className={clsx(classes.title, !exportCheckbox.pdf && classes.unselected_checkbox)}
                            checked={exportCheckbox.pdf}
                            onChange={onExportCheckboxChange}
                            control={<Checkbox color="default" />}
                            label="PDF (limité à 5 points max)"
                            labelPlacement="end"
                        />
                    </FormGroup>
                </FormControl>
                <div className={classes.export_button_container}>
                    <button
                        className={Object.values(exportCheckbox).every((elem) => elem === false) ? classes.disabled_export_button : classes.export_button}
                        onClick={onExport}
                    >
                        {downloadLoading ?
                            <CircularProgress />
                            :
                            "Exporter"
                        }
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default ExportPointsModal;
