import React from 'react';
import { Typography, Grid, InputBase, Radio } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import useStyles from './styles/SearchById';

const SearchById = ({ radios, setRadios, searchForm, setSearchForm }) => {

    const classes = useStyles();

    const onToggleRadio = (event) => {
		setRadios({ ...radios, [`${event.target.value}`]: !radios[`${event.target.value}`] });
    }

    const onSearchValueChange = (event) => {
		setSearchForm({ ...searchForm, [`${event.target.name}`]: event.target.value });
	}

    return (
        <div>
            <Grid container className={classes.drawer_table_row}>
                <Grid item xs={1} className={classes.drawer_table_column}>
                    <Radio
                        value="id"
                        color="primary"
                        className={classes.radio}
                        checked={radios.id} onClick={onToggleRadio}
                        icon={<RadioButtonUncheckedIcon className={classes.radio_size} />}
                        checkedIcon={<RadioButtonCheckedIcon className={classes.radio_size} />}
                    />
                </Grid>
                <Grid item xs={7}>
                    <Typography className={classes.drawer_text}>N° de plaquette</Typography>
                </Grid>
                <Grid item xs={4}>
                    <InputBase
                        name="id"
                        placeholder="Remplir ici..."
                        className={classes.drawer_text}
                        onChange={onSearchValueChange}
                        value={searchForm.id}
                        type="number"
                    />
                </Grid>
            </Grid>

            <Grid container className={classes.drawer_table_row}>
                <Grid item xs={1} className={classes.drawer_table_column}>
                    <Radio
                        value="id_above"
                        color="primary"
                        className={classes.radio}
                        checked={radios.id_above} onClick={onToggleRadio}
                        icon={<RadioButtonUncheckedIcon className={classes.radio_size} />}
                        checkedIcon={<RadioButtonCheckedIcon className={classes.radio_size} />}
                    />
                </Grid>
                <Grid item xs={7}>
                    <Typography className={classes.drawer_text}>N° de plaquette au-dessus de</Typography>
                </Grid>
                <Grid item xs={4}>
                    <InputBase
                        name="id_above"
                        placeholder="Remplir ici..."
                        className={classes.drawer_text}
                        onChange={onSearchValueChange}
                        value={searchForm.id_above}
                        type="number"
                    />
                </Grid>
            </Grid>

            <Grid container className={classes.drawer_table_row}>
                <Grid item xs={1} className={classes.drawer_table_column}>
                    <Radio
                        value="id_below"
                        color="primary"
                        className={classes.radio}
                        checked={radios.id_below} onClick={onToggleRadio}
                        icon={<RadioButtonUncheckedIcon className={classes.radio_size} />}
                        checkedIcon={<RadioButtonCheckedIcon className={classes.radio_size} />}
                    />
                </Grid>
                <Grid item xs={7}>
                    <Typography className={classes.drawer_text}>N° de plaquette en-dessous de</Typography>
                </Grid>
                <Grid item xs={4}>
                    <InputBase
                        name="id_below"
                        placeholder="Remplir ici..."
                        className={classes.drawer_text}
                        onChange={onSearchValueChange}
                        value={searchForm.id_below}
                        type="number"
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default SearchById;
