import React, { useContext, useState } from 'react';
import { Typography, Grid, TextField } from '@material-ui/core';

import CombinedContext from '../../../contexts/CombinedContext';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	label: {
		fontSize: 12.5,
		color: 'black',
		fontWeight: 'bold',
	},
	textfield_text: {
		fontSize: 12.5,
		color: 'gray',
	},
	textfield: {
		marginLeft: '-3px'
	},
	grid_contain: {
		paddingLeft:'50px',
		margin: '25px 0'
	}
}));


const InputDatePoint = (props) => {

	const { title, att } = props;

	const classes = useStyles();

	const [combinedState, setForm] = useContext(CombinedContext);
	const form = combinedState.point.point_form;

	const convertStringTodate = (str) => {
		const date = new Date(str);
		return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
	};

	const initValue = form[att] ? convertStringTodate(form[att]) : ''
	const [value, setValue] = useState(initValue);

	const handleChange = (event) => {
		setValue(event.target.value);
		setForm({ type: 'SET_POINT_FORM', point_form: { [att]: event.target.value } });
	};

	
	return (
		<Grid item md={4} sm={6} xs={12}>
			<div className={classes.grid_contain}>
				<Typography className={classes.label}>{title}</Typography>
				<TextField
					value={value}
					onChange={handleChange}
					type="date"
					InputProps={{
						disableUnderline: true,
						className: classes.textfield_text
					}}
					className={classes.textfield}
				/>
			</div>
		</Grid>
	);
}

export default InputDatePoint;
