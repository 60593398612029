import React, { useContext } from 'react';

import { Snackbar } from '@material-ui/core';

import { Alert } from '@material-ui/lab';

import CombinedContext from '../../contexts/CombinedContext';

const SnackbarComponent = () => {

	const [state, dispatch] = useContext(CombinedContext);

	const { message, isSnackbarOpened, severity, duration } = state.snackbar;


	const onClose = (event, reason) => {
		if (reason === 'clickaway')
			return;
		dispatch({ type: "CLOSE_SNACKBAR" });
	};
	
	return (
		<>
			{severity ?
				<Snackbar
					open={isSnackbarOpened}
					autoHideDuration={duration ? duration : 3000}
					resumeHideDuration={duration ? duration : 3000}
					onClose={onClose}
				>
					<Alert onClose={onClose} severity={severity}>
						{message}
					</Alert>
				</Snackbar>
			:
				<Snackbar
					open={isSnackbarOpened}
					autoHideDuration={duration ? duration : 3000}
					resumeHideDuration={duration ? duration : 3000}
					onClose={onClose}
					message={message}
				/>
			}
		</>
	);
};

export default SnackbarComponent;
