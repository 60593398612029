import React from 'react';

import Contact from './Contact';
import Help from './Help';

const BottomLeftControls = () => {
    return (
        <div>
			<Contact />
			<Help />
        </div>
    )
}

export default BottomLeftControls;
