import React, { useContext, useState } from 'react';
import { Typography, InputBase, CircularProgress, Button } from '@material-ui/core';

import Layout from './Layout';

import { login } from '../../api/POST';

import CombinedContext from '../../contexts/CombinedContext';

import useStyles from './styles/Login';

const Login = (props) => {

    const classes = useStyles();

    const [, dispatch] = useContext(CombinedContext);

    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        id: '',
        password: ''
    });
    const [errors, setErrors] = useState({
        id: false,
        password: false
    });

    const onConnect = async (event) => {
        event.preventDefault();
        setErrors({
			id: !Boolean(form.id),
			password: !Boolean(form.password)
        });
        if (Boolean(form.id && form.password)) {
            setLoading(true);
            const formData = new FormData();
            formData.append('email', form.id);
            formData.append('password', form.password);
            const res = await login(formData);
            setLoading(false);
            if (!res)
                return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Erreur inconnue, vérifier votre connexion internet', severity: 'error' });
            switch (res.status) {
                case 200:
                    return props.history.push({ pathname: '/admin-geo/home' });
                case 401:
                    return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Identifiant ou mot de passe invalide', severity: 'error' });
                case 404:
                    return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Serveur introuvable', severity: 'error' });
                default:
                    return dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Erreur inconnue, vérifier votre connexion internet', severity: 'error' });
            }
		}
    }

    return (
        <Layout brand='assets/images/SmartTopo_logo.svg'>
            <form onSubmit={onConnect}>
                <div className={classes.login_container}>
                    <img src='assets/images/Logo.svg' alt='' draggable={false} className={classes.logo} />
                    <Typography className={classes.title}>SE CONNECTER</Typography>
                        <InputBase
                            placeholder="Votre identifiant"
                            value={form.id}
                            onChange={(event) => setForm({ ...form, id: event.target.value })}
                            className={errors.id ? classes.input_base_error : classes.input_base}
                            classes={{ focused: classes.input_base_focus }}
                            inputProps={{ maxLength: 100 }}
                        />
                        <InputBase
                            placeholder="Votre mot de passe"
                            value={form.password}
                            type="password"
                            onChange={(event) => setForm({ ...form, password: event.target.value })}
                            className={errors.password ? classes.input_base_error : classes.input_base}
                            classes={{ focused: classes.input_base_focus }}
                            inputProps={{ maxLength: 100 }}
                        />
                        {loading ?
                            <CircularProgress className={classes.loading} />
                        :
                            <Button type="submit" className={classes.button}>Connexion</Button>
                        }
                </div>
            </form>
        </Layout>
    );
}

export default Login;
