import { useReducer } from 'react';
import mapboxReducer, { mapboxInitialState } from './mapboxReducer';
import snackbarReducer, { snackbarInitialState } from './snackbarReducer';
import adminDateReducer, { adminDataInitialState } from './adminDataReducer';
import pointInfoReducer, { pointInfoInitialState } from './pointInfoReducer';

const useCombinedReducers = () => {

	const combinedReducers = {
		mapbox: useReducer(mapboxReducer, mapboxInitialState),
		snackbar: useReducer(snackbarReducer, snackbarInitialState),
		admin: useReducer(adminDateReducer, adminDataInitialState),
		point: useReducer(pointInfoReducer, pointInfoInitialState)
	}

	// Global State
	const state = Object.keys(combinedReducers).reduce(
		(acc, key) => ({ ...acc, [`${key}`]: combinedReducers[`${key}`][0] }), {});

	// Global Dispatch Function
	const dispatch = action =>
		Object.keys(combinedReducers)
			.map(key => combinedReducers[`${key}`][1])
			.forEach(fn => fn(action));

	return [state, dispatch];
};

export default useCombinedReducers;
