import React, { useContext, useEffect, useState } from 'react';
import { Typography, FormControlLabel, CircularProgress, Switch, Grid, Button, MenuItem } from '@material-ui/core';

import { getPoint } from '../../../api/GET';

import CombinedContext from '../../../contexts/CombinedContext';

import Layout from '../Layout';
import DeletePointModal from './DeletePointModal';
import SaveModal from './SaveModal';
import InputTextPoint from './InputTextPoint';
import InputDatePoint from './InputDatePoint';
import InputSelectPoint from './InputSelectPoint';

import useStyles from './styles/Point';

const MainComponent = (props) => {
	const { id } = props.match.params;

	const classes = useStyles();

	const [combinedState, combinedDispatch] = useContext(CombinedContext);
	const form = combinedState.point.point_form;
	const setForm = combinedDispatch;

	const [dataLoading, setDataLoading] = useState(false);
	const [point, setPoint] = useState(null);

	const [deleteModal, setDeleteModal] = useState(false);
	const [saveModal, setSaveModal] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setDataLoading(true);
			const res = await getPoint(id);
			if (res && res.status === 404)
				return props.history.goBack();
			if (res) {
				setPoint(res);
				setForm({ type: 'SET_POINT_FORM', point_form: res });
			}
			setDataLoading(false);
		}

		fetchData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!form.is_inside_sncf)
			setForm({ type: 'SET_POINT_FORM', point_form: { track: '' } });

	}, [form.is_inside_sncf]); // eslint-disable-line react-hooks/exhaustive-deps

	const onSwitchChange = () => {
		setForm({ type: 'SET_POINT_FORM', point_form: { is_published: !form.is_published } });
	}

	const onDelete = () => {
		setDeleteModal(true);
	}

	const onSave = () => {
		setSaveModal(true);
	}

	const handleBack = () => {
		setForm({ type: 'SET_POINT_FORM' });
		props.history.push({ pathname: '/admin-geo/home' })
	};


	return (
		<Layout brand='../../../assets/images/SmartTopo_logo.svg'>
			<div className={classes.root}>
				<Typography className={classes.return} onClick={handleBack}>{'<'} Retour</Typography>
				{dataLoading || !point ?
					<div className={classes.data_loading_container}>
						<CircularProgress />
					</div>
					:
					<div className={classes.content_container}>
						<Grid container className={classes.title_container}>
							<Grid item xs={4} />
							<Grid item xs={4} className={classes.center}>
								<Typography className={classes.title}>POINT {id}</Typography>
							</Grid>
							<Grid item xs={4} className={classes.center}>
								<FormControlLabel
									control={
										<Switch
											classes={{
												root: classes.publish_root,
												switchBase: classes.publish_switchBase,
												thumb: classes.publish_thumb
											}}
										/>
									}
									label={form.is_published ? "Publié" : "Non publié"}
									checked={form.is_published}
									onChange={onSwitchChange}
									labelPlacement="start"
									className={classes.switch_text_color}
								/>
							</Grid>
						</Grid>

						<Grid container>
							<InputTextPoint title='Matérialisation' att='material' />
							<InputTextPoint title='Projection' att='native_proj' />
							<InputTextPoint title='Ligne' att='line' />
							<InputTextPoint title='Est' att='native_east' />
							<InputTextPoint title='Nord' att='native_north' />
							<InputTextPoint title='Pk' att='kp' />
							<InputSelectPoint title='En emprise' att='is_inside_sncf' >
								<MenuItem value="oui">
									<Typography className={classes.textfield_text}>Oui</Typography>
								</MenuItem>
								<MenuItem value="non">
									<Typography className={classes.textfield_text}>Non</Typography>
								</MenuItem>
							</InputSelectPoint>
							<InputTextPoint title='Système alti' att='native_vrs' />
							<InputTextPoint title='Voie' att='track' disabled={!form.is_inside_sncf} />
							<InputTextPoint title='Altitude' att='native_alti' />
							<InputSelectPoint title='Statut' att='status' >
								<MenuItem value="EN BON ETAT">
									<Typography className={classes.textfield_text}>EN BON ÉTAT</Typography>
								</MenuItem>
								<MenuItem value="NON RETOURVE">
									<Typography className={classes.textfield_text}>NON RETROUVÉ</Typography>
								</MenuItem>
								<MenuItem value="IMPRENABLE">
									<Typography className={classes.textfield_text}>IMPRENABLE</Typography>
								</MenuItem>
								<MenuItem value="MAUVAISE ETAT">
									<Typography className={classes.textfield_text}>MAUVAISE ÉTAT</Typography>
								</MenuItem>
								<MenuItem value="PRESUME DEPLACE">
									<Typography className={classes.textfield_text}>PRÉSUMÉ DÉPLACÉ</Typography>
								</MenuItem>
							</InputSelectPoint>
							<InputDatePoint title='Date de statut' att='statuted_at' />
							<InputDatePoint title='Calculé le' att='computed_at' />
						</Grid>

						<div className={classes.button_container}>
							<Button className={classes.button} onClick={onDelete}>Supprimer le point</Button>
							<Button className={classes.button} onClick={onSave}>Enregistrer les modifications</Button>
						</div>
					</div>
				}
				<DeletePointModal open={deleteModal} setOpen={setDeleteModal} id={id} history={props.history} />
				<SaveModal open={saveModal} setOpen={setSaveModal} id={id} form={form} />
			</div>
		</Layout>
	);
}

export default MainComponent;
