import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { Typography, InputBase, Button, Modal, Backdrop, CircularProgress, Tooltip } from '@material-ui/core';
import { Check, Error, HighlightOff, Email } from '@material-ui/icons';
import Captcha from 'react-captcha-code';

import { sendEmail } from '../../../../api/POST';

import useStyles from './styles/Contact';

const Contact = () => {

    const classes = useStyles();

    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({
		email: '',
		fullname: '',
        message: '',
        captcha: ''
	})
	const [errors, setErrors] = useState({
		email: false,
        message: false,
        captcha: false
	});
	const [send, setSend] = useState(false);
	const [sendError, setSendError] = useState(false);
    const [sendIsLoading, setSendIsLoading] = useState(false);
    const [captcha, setCaptha] = useState('');

    const onToggleModal = () => {
		setSend(false);
		setSendError(false);
		setErrors({
			email: false,
            message: false,
            captcha: false
		});
		setModal(false);
    }

    const onCheckErrors = () => {
        setErrors({
			email: Boolean(!form.email),
            message: Boolean(!form.message),
            captcha: Boolean(!form.captcha || captcha !== form.captcha)
        });
        if (Boolean(form.email && form.message && form.captcha))
            return false;
        return true;
    }
    
    const onSendMessage = async (event) => {
        event.preventDefault();
		if (!onCheckErrors()) {
			setSendIsLoading(true);
			const formData = new FormData();
			formData.append('sender_name', form.fullname);
			formData.append('sender_address', form.email);
			formData.append('body', form.message);
			const res = await sendEmail(formData);
			if (res) {
				setForm({
					email: '',
					fullname: '',
                    message: '',
                    captcha: ''
				});
			} else {
				setSendError(true);
			}
			setSend(true);
			setSendIsLoading(false);
		}
    }

    const handleClick = useCallback((code) => {
        setCaptha(code);
    }, []);

    return (
        <div>
            <Tooltip title="Nous contacter" placement="right">
                <div id="Contact" className={classes.control_button_container} onClick={() => setModal(true)}>
                    <Email fontSize="small" className={classes.controls_icon} />
                </div>
            </Tooltip>
            <Modal
                onClose={onToggleModal}
                open={modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
                className={classes.modal}
            >
                <div className={classes.modal_root}>
                    <HighlightOff className={classes.modal_close_button} onClick={onToggleModal} />
                    {!send ?
                        <form onSubmit={onSendMessage}>
                            <div className={classes.modal_content_container}>
                                <div className={classes.horizontal_center}>
                                    <Typography className={classes.title}>NOUS ECRIRE</Typography>
                                </div>
                                <InputBase
                                    placeholder="Votre adresse email*"
                                    value={form.email}
                                    onChange={(event) => setForm({ ...form, email: event.target.value })}
                                    className={errors.email ? classes.input_base_error : classes.input_base}
                                    classes={{ focused: classes.input_base_focus }}
                                    inputProps={{ maxLength: 100 }}
                                />
                                <InputBase
                                    placeholder="Votre nom et prénom"
                                    value={form.fullname}
                                    onChange={(event) => setForm({ ...form, fullname: event.target.value })}
                                    className={classes.input_base}
                                    classes={{ focused: classes.input_base_focus }}
                                    maxLength="10"
                                    inputProps={{ maxLength: 100 }}
                                />
                                <InputBase
                                    placeholder="Votre message...*"
                                    value={form.message}
                                    onChange={(event) => setForm({ ...form, message: event.target.value })}
                                    multiline
                                    rows={5}
                                    className={errors.message ? classes.input_base_error : classes.input_base}
                                    classes={{ focused: classes.input_base_focus }}
                                    inputProps={{ maxLength: 10000 }}
                                />
                                <div className={classes.captcha_container}>
                                    <Captcha charNum={6} onChange={handleClick} />
                                    <InputBase
                                        placeholder="Ecrire les caractères à gauche*"
                                        onChange={(event) => setForm({ ...form, captcha: event.target.value })}
                                        className={errors.captcha ? classes.captcha_error : classes.captcha_input}
                                        classes={{ focused: classes.input_base_focus }}
                                    />
                                </div>
                                <div className={classes.horizontal_center}>
                                    {sendIsLoading ?
                                        <CircularProgress className={classes.loading} />
                                    :
                                        <Button type="submit" className={classes.button}>Envoyer</Button>
                                    }
                                </div>
                            </div>
                        </form>
                    :
                        <div className={classes.modal_response_main_container}>
                            <div className={classes.modal_response_container}>
                                {sendError ?
                                    <>
                                        <div className={clsx(classes.response_icon_container, classes.failure_icon)}>
                                            <Error className={classes.response_content_color} />
                                        </div>
                                        <div className={clsx(classes.response_message_container, classes.failure_message)}>
                                            <Typography className={classes.response_content_color}>Votre message n'a pas pu être envoyé</Typography>
                                        </div>
                                    </>
                                :
                                    <>
                                        <div className={clsx(classes.response_icon_container, classes.success_icon)}>
                                            <Check className={classes.response_content_color} />
                                        </div>
                                        <div className={clsx(classes.response_message_container, classes.success_message)}>
                                            <Typography className={classes.response_content_color}>Votre message a bien été envoyé</Typography>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        </div>
    );
}

export default Contact;

