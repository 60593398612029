import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	radio: {
		padding: 0
	},
	radio_size: {
		color: theme.palette.blue_flash,
		fontSize: 20,

		"@media (max-width: 1024px)": {
			fontSize: 15
		},

		"@media (max-width: 768px)": {
			fontSize: 12.5
		},

		"@media (max-width: 425px)": {
			fontSize: 10
		}
	},
	drawer_text: {
		fontWeight: 'bold',
		fontSize: 12.5,

		"@media (max-width: 768px)": {
			fontSize: 10
		}
	},
	drawer_table_row: {
		marginBottom: 10,
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 5px gray`,
		display: 'flex',
		alignItems: 'center',
		height: 40,

		"@media (max-width: 1024px)": {
			height: 35
		},

		"@media (max-width: 768px)": {
			height: 30
		},

		"@media (max-width: 425px)": {
			height: 25
		}
	},
	drawer_table_column: {
		display: 'flex',
		justifyContent: 'center'
	}
}));

export default useStyles;
