import mapboxgl from 'mapbox-gl';
import CustomControl from './CustomControl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

const EventsListener = (map, draw) => {
    map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
    map.addControl(
        new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        }),
        'bottom-right'
    );
    map.addControl(new mapboxgl.NavigationControl({ showZoom: false }), 'bottom-right');
    const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        placeholder: "Chercher un lieu",
        enableEventLogging: false
    });
    document.getElementById('geocoder').appendChild(geocoder.onAdd(map));

    map.addControl(new CustomControl({ element_id: "SearchByAtributes" }), 'top-left');
    map.addControl(draw);

    map.addControl(new CustomControl({ element_id: "Help" }), 'bottom-left');
    map.addControl(new CustomControl({ element_id: "Contact" }), 'bottom-left');
}

export default EventsListener;
