import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    appbar: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 0px 10px gray',
        justifyContent: 'space-between',
        paddingLeft: 25,
		paddingRight: 10,
		'& img' :{
			width: '200px'
		},

        "@media (max-width: 1024px)": {
            paddingLeft: 20
		},

		"@media (max-width: 768px)": {
            paddingLeft: 15
        },
        
        "@media (max-width: 425px)": {
            paddingLeft: 10
		}
    },
    loading_root: {
        position: 'absolute',
        top: 50,
        left: 0,
        right: 0,
        bottom: 0
    },
    loading_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    }
}));

export default useStyles;
