import { createMuiTheme } from '@material-ui/core/styles';

export const themeInstance = createMuiTheme({
	palette: {
		dark_gray: '#383838',
		navy_blue: '#3A4C78',
		blue_flash: '#5677DB',
		ghost: '#BFBFBF'
	}
});
