import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	control_button_container: {
		backgroundColor: 'white',
		cursor: 'pointer',
		width: 27,
		height: 27,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 5,
		border: '2px solid rgba(0, 0, 0, 0.2)',
		boxShadow: '2px 2px 2px gray',

		'&:hover': {
			backgroundColor: '#F2F2F2'
		}
	},
	controls_icon: {
		color: theme.palette.dark_gray
	},
	modal_close_button: {
		position: 'absolute',
		top: 10,
		right: 10,
		cursor: 'pointer',
		color: theme.palette.ghost,
		height: 50,
		width: 50,
		alignSelf: 'flex-end',

		'&:hover': {
			color: 'gray'
		},

		"@media (max-width: 1024px)": {
			top: 7.5,
			right: 7.5,
			height: 45,
			width: 45
		},
		
		"@media (max-width: 768px)": {
			top: 5,
			right: 5,
			height: 40,
			width: 40
		},
		
		"@media (max-width: 425px)": {
			top: 2.5,
			right: 2.5,
			height: 35,
			width: 35
		}
	},
	modal: {
		display:'flex',
		alignItems:'center',
		justifyContent:'center'
	},
	modal_root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'white',
		outline: 'none',
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px gray`,
		height: '60%',
		width: '50%',

		"@media (max-width: 1024px)": {
			width: '65%'
		},
		
		"@media (max-width: 768px)": {
			width: '80%'
		},
		
		"@media (max-width: 425px)": {
			width: '95%'
		}
    },
    modal_content_container: {
		margin: '0px 50px 50px 50px',
		overflowY: 'auto',
		overflowX: 'hidden',

		"@media (max-width: 1024px)": {
			margin: '0px 40px 40px 40px'
		},
		
		"@media (max-width: 768px)": {
			margin: '0px 30px 30px 30px'
		},
		
		"@media (max-width: 425px)": {
			margin: '0px 20px 20px 20px'
		}
    },
    horizontal_center: {
		display: 'flex',
		justifyContent: 'center'
    },
    title: {
		fontWeight: 'bold',
		margin: '30px 0px 20px 0px',
		fontSize: 15,

		"@media (max-width: 1024px)": {
			margin: '35px 0px 15px 0px'
		},
		
		"@media (max-width: 768px)": {
			margin: '40px 0px 10px 0px'
		},
		
		"@media (max-width: 425px)": {
			margin: '45px 0px 5px 0px'
		}
	},
	text: {
		color: 'gray'
	}
}))

export default useStyles;
