import React, { useContext, useState } from 'react';
import { Typography, Grid, Select } from '@material-ui/core';

import CombinedContext from '../../../contexts/CombinedContext';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	label: {
		fontSize: 12.5,
		color: 'black',
		fontWeight: 'bold',
	},
	textfield_text: {
		fontSize: 12.5,
		color: 'gray',
	},
	select_icon: {
        display: 'none'
	},
	select: {
        width: '100%',
        color: 'gray'
	},
	grid_contain: {
		paddingLeft:'50px',
		margin: '25px 0'
	}
}));


const InputSelectPoint = (props) => {

	const { title, att, children } = props;

	const classes = useStyles();

	const [combinedState, setForm] = useContext(CombinedContext);
	const form = combinedState.point.point_form;

	const formValue = (att) => {
		if (att === 'is_inside_sncf') return form.is_inside_sncf ? 'oui' : 'non';
		if (att === 'status') return form.status ? form.status.toUpperCase() : '';
		return form[att] ? form[att] : '';
	};

	const [value, setValue] = useState(formValue(att));

	const handleChange = (event) => {
		setValue(event.target.value);
		setForm({ type: 'SET_POINT_FORM', point_form: { [att]: event.target.value } });
	};

	
	return (
		<Grid item md={4} sm={6} xs={12}>
			<div className={classes.grid_contain}>
				<Typography className={classes.label}>{title}</Typography>
				<Select
					classes={{ icon: classes.select_icon }}
					value={value}
					onChange={handleChange}
					disableUnderline
					className={classes.select}
				>
					{children}
				</Select>
			</div>
		</Grid>

	);
}

export default InputSelectPoint;
