import React, { useEffect, useRef, useContext } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import CombinedContext from '../../../contexts/CombinedContext';

import LoadImages from './tools/LoadImages';
import LoadSources from './tools/LoadSources';
import AddLayers from './tools/AddLayers';
import EventsListener from './tools/EventsListener';
import AddControls from './tools/AddControls';

import { getPointsBySearch } from '../../../api/GET';

import useStyles from './styles/Mapbox';

const Mapbox = ({ geojson, urlParams }) => {

	const classes = useStyles();

	const mapContainerRef = useRef(null);

	const [state, dispatch] = useContext(CombinedContext);

	const polygonColors = {
		inactive: '#fbb03b',
		active: '#3bb2d0'
	}

	// https://docs.mapbox.com/mapbox-gl-js/example/mapbox-gl-draw/
	const draw = new MapboxDraw({
		displayControlsDefault: false,
		userProperties: true,
		styles: [
			{
                'id': 'gl-draw-polygon-fill-inactive',
                'type': 'fill',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'Polygon'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'fill-color': polygonColors.inactive,
                    'fill-outline-color': polygonColors.inactive,
                    'fill-opacity': 0.1
                }
            },
            {
                'id': 'gl-draw-polygon-fill-active',
                'type': 'fill',
                'filter': ['all', ['==', 'active', 'true'],
                    ['==', '$type', 'Polygon']
                ],
                'paint': {
                    'fill-color': polygonColors.active,
                    'fill-outline-color': polygonColors.active,
                    'fill-opacity': 0.1
                }
            },
            {
                'id': 'gl-draw-polygon-midpoint',
                'type': 'circle',
                'filter': ['all', ['==', '$type', 'Point'],
                    ['==', 'meta', 'midpoint']
                ],
                'paint': {
                    'circle-radius': 3,
                    'circle-color': polygonColors.active
                }
            },
            {
                'id': 'gl-draw-polygon-stroke-inactive',
                'type': 'line',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'Polygon'],
                    ['!=', 'mode', 'static']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': polygonColors.inactive,
                    'line-width': 2
                }
            },
            {
                'id': 'gl-draw-polygon-stroke-active',
                'type': 'line',
                'filter': ['all', ['==', 'active', 'true'],
                    ['==', '$type', 'Polygon']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': polygonColors.active,
                    'line-dasharray': [0.2, 2],
                    'line-width': 2
                }
            },
            {
                'id': 'gl-draw-line-inactive',
                'type': 'line',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'LineString'],
                    ['!=', 'mode', 'static']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': polygonColors.inactive,
                    'line-width': 2
                }
            },
            {
                'id': 'gl-draw-line-active',
                'type': 'line',
                'filter': ['all', ['==', '$type', 'LineString'],
                    ['==', 'active', 'true']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': polygonColors.active,
                    'line-dasharray': [0.2, 2],
                    'line-width': 2
                }
            },
            {
                'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
                'type': 'circle',
                'filter': ['all', ['==', 'meta', 'vertex'],
                    ['==', '$type', 'Point'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'circle-radius': 5,
                    'circle-color': '#fff'
                }
            },
            {
                'id': 'gl-draw-polygon-and-line-vertex-inactive',
                'type': 'circle',
                'filter': ['all', ['==', 'meta', 'vertex'],
                    ['==', '$type', 'Point'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'circle-radius': 3,
                    'circle-color': polygonColors.active
                }
            },
            {
                'id': 'gl-draw-point-point-stroke-inactive',
                'type': 'circle',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'Point'],
                    ['==', 'meta', 'feature'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'circle-radius': 5,
                    'circle-opacity': 1,
                    'circle-color': '#fff'
                }
            },
            {
                'id': 'gl-draw-point-inactive',
                'type': 'circle',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'Point'],
                    ['==', 'meta', 'feature'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'circle-radius': 3,
                    'circle-color': polygonColors.inactive
                }
            },
            {
                'id': 'gl-draw-point-stroke-active',
                'type': 'circle',
                'filter': ['all', ['==', '$type', 'Point'],
                    ['==', 'active', 'true'],
                    ['!=', 'meta', 'midpoint']
                ],
                'paint': {
                    'circle-radius': 7,
                    'circle-color': '#fff'
                }
            },
            {
                'id': 'gl-draw-point-active',
                'type': 'circle',
                'filter': ['all', ['==', '$type', 'Point'],
                    ['!=', 'meta', 'midpoint'],
                    ['==', 'active', 'true']
                ],
                'paint': {
                    'circle-radius': 5,
                    'circle-color': polygonColors.active
                }
            },
            {
                'id': 'gl-draw-polygon-fill-static',
                'type': 'fill',
                'filter': ['all', ['==', 'mode', 'static'],
                    ['==', '$type', 'Polygon']
                ],
                'paint': {
                    'fill-color': '#404040',
                    'fill-outline-color': '#404040',
                    'fill-opacity': 0.1
                }
            },
            {
                'id': 'gl-draw-polygon-stroke-static',
                'type': 'line',
                'filter': ['all', ['==', 'mode', 'static'],
                    ['==', '$type', 'Polygon']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': '#404040',
                    'line-width': 2
                }
            },
            {
                'id': 'gl-draw-line-static',
                'type': 'line',
                'filter': ['all', ['==', 'mode', 'static'],
                    ['==', '$type', 'LineString']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': '#404040',
                    'line-width': 2
                }
            },
            {
                'id': 'gl-draw-point-static',
                'type': 'circle',
                'filter': ['all', ['==', 'mode', 'static'],
                    ['==', '$type', 'Point']
                ],
                'paint': {
                    'circle-radius': 5,
                    'circle-color': '#404040'
                }
            },
            {
                'id': 'gl-draw-polygon-color-picker',
                'type': 'fill',
                'filter': ['all', ['==', '$type', 'Polygon'],
                    ['has', 'user_portColor']
                ],
                'paint': {
                    'fill-color': ['get', 'user_portColor'],
                    'fill-outline-color': ['get', 'user_portColor'],
                    'fill-opacity': 0.5
                }
            },
            {
                'id': 'gl-draw-line-color-picker',
                'type': 'line',
                'filter': ['all', ['==', '$type', 'LineString'],
                    ['has', 'user_portColor']
                ],
                'paint': {
                    'line-color': ['get', 'user_portColor'],
                    'line-width': 2
                }
            },
            {
                'id': 'gl-draw-point-color-picker',
                'type': 'circle',
                'filter': ['all', ['==', '$type', 'Point'],
                    ['has', 'user_portColor']
                ],
                'paint': {
                    'circle-radius': 3,
                    'circle-color': ['get', 'user_portColor']
                }
            }
		]
	});

	useEffect(() => {
		const defaultCenter = [2.505555556, 46.82361111, 6];
		const params = new URLSearchParams(urlParams);
		const lat = parseFloat(params.get('lat'));
		const lng = parseFloat(params.get('lng'));
		const zoom = parseFloat(params.get('zoom'));

		if (lat && lng) {
			defaultCenter[0] = lng;
			defaultCenter[1] = lat;
			if (zoom && zoom > 0.0 && zoom <= 20.0)
				defaultCenter[2] = zoom;
		}

		// https://docs.mapbox.com/mapbox-gl-js/api/
		mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
		const map = new mapboxgl.Map({
			logoPosition: 'bottom-right',
			container: mapContainerRef.current,
			style: 'mapbox://styles/sncflveatt/ckb948hks0nj91iolefpfttva', // stylesheet location
			center: [defaultCenter[0], defaultCenter[1]], // starting position [lng, lat]
			zoom: defaultCenter[2]
		});

		dispatch({ type: 'SET_MAPBOX_ALL', ...state.mapbox, map, draw, mapRef: mapContainerRef });

		AddControls(map, draw);

		map.on('load', () => {
			LoadImages(map);
			LoadSources(map, geojson);
			AddLayers(map);
			EventsListener(map, draw);
		});

		const newPolygon = async () => {
			const polygons = draw.getAll();
			const results = [];
			for (let i = 0; i < polygons.features.length; i++) {
				const geojon = {
					type: "FeatureCollection",
					features: [polygons.features[i]]
				}
				const res = await getPointsBySearch(JSON.stringify(geojon));
				if (res)
					results.push(...res.features);
			}
			dispatch({ type: 'SET_MAPBOX_SELECTED_POINTS', selectedPoints: results });
			dispatch({ type: 'SET_MAPBOX_NEW_POLYGON', newPolygon: true });
			if (results.length === 0)
				dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Aucun point', severity: 'warning' });
		}
		
		map.on('draw.create', newPolygon);
		map.on('draw.update', newPolygon);

		// clean up on unmount
		return () => map.remove();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	
	return (
		<div className={classes.map_container} ref={mapContainerRef} />
	);
}

export default Mapbox;
