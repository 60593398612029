import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	control_button_container: {
		backgroundColor: 'white',
		cursor: 'pointer',
		width: 27,
		height: 27,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 5,
		border: '2px solid rgba(0, 0, 0, 0.2)',
		boxShadow: '2px 2px 2px gray',

		'&:hover': {
			backgroundColor: '#F2F2F2'
		}
	},
	active_search_by_points: {
		backgroundColor: 'white',
		cursor: 'pointer',
		width: 27,
		height: 27,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 2,

		'&:hover': {
			backgroundColor: '#F2F2F2'
		}
	},
	reload_icon: {
		cursor: 'pointer',
		marginTop: 5,
		marginRight: 5,
		padding: 5,
		fontSize: 17.5,
		color: theme.palette.blue_flash,
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 5px ${theme.palette.ghost}`,

		'&:hover': {
			backgroundColor: '#F2F2F2'
		}
	},
	reload_loading: {
		marginRight: 5,
		padding: 5,
		color: theme.palette.blue_flash
	},
	controls_icon: {
		color: theme.palette.dark_gray
	},
	hide: {
		display: 'none'
	},
	drawer_root: {
		backgroundColor: 'white',
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px gray`,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		overflowX: 'hidden',

		"@media (max-height: 750px)": {
			height: window.innerHeight - 75,
		},
	},
	drawer_title_main_container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	drawer_title_container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	drawer_title: {
		fontWeight: 'bold',
		marginLeft: 10,
		fontSize: 12.5
	},
	drawer_result_title: {
		fontWeight: 'bold',
		marginLeft: 10,
		fontSize: 12.5,
		padding: 5,
		borderRadius: 5,
		border: `3px solid ${theme.palette.blue_flash}`
	},
	drawer_content: {
		padding: '0px 41px 0px 41px',

		"@media (max-width: 1024px)": {
			padding: '0px 31px 0px 31px'
		},

		"@media (max-width: 768px)": {
			padding: '0px 21px 0px 21px'
		},

		"@media (max-width: 425px)": {
			padding: '0px 6px 0px 6px'
		}
	},
	drawer_bottom_container: {
		display: 'flex',
		justifyContent: 'center'
	},
	export_button: {
		backgroundColor: theme.palette.blue_flash,
		color: 'white',
		width: '48%',
		height: 50,
		textTransform: 'none',
		fontWeight: 'bold',
		margin: '15px 0px 15px 0px'
	},
	drawer_toggle_attributes: {
		backgroundColor: theme.palette.blue_flash,
		color: 'white',
		textTransform: 'none',
		width: '100%',
		margin: '15px 0px 15px 0px',
		fontWeight: 'bold'
	},
	drawer_tools_icon_container: {
		display: 'flex',
		justifyContent: 'center'
	},
	drawer_result_container: {
		display: 'flex',
		justifyContent: 'center'
	}
}));

export default useStyles;
