import { axiosGET } from '../axios/axios';

const domain = process.env.REACT_APP_SERVEUR_ENDPOINT;

export const getAllPoints = () => {
    const url = `${domain}/api/points`;
    return axiosGET(url);
}

export const getPoint = (id) => {
    const url = `${domain}/api/points/${id}`;
    return axiosGET(url);
}

export const getPublishedPoints = () => {
    const url = `${domain}/api/points?is_published=1&export=geojson&timestamp=${new Date().getTime()}`;
    return axiosGET(url);
}

export const getPointPdf = (id) => {
    const url = `${domain}/api/points/${id}?export=pdf`;
    return axiosGET(url, 'blob');
}

export const getSearchResults = (params) => {
    const url = `${domain}/api/points?${params}&export=geojson`;
    return axiosGET(url);
}

export const getPointsBySearch = (params) => {
    const url = `${domain}/api/points?border=${params}&export=geojson`;
    return axiosGET(url); 
}