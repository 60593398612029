import React, { useContext, useState } from 'react';
import { Typography, Grid, TextField } from '@material-ui/core';

import CombinedContext from '../../../contexts/CombinedContext';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	label: {
		fontSize: 12.5,
		color: 'black',
		fontWeight: 'bold',
	},
	textfield_text: {
		fontSize: 12.5,
		color: 'gray'
	},
	grid_contain: {
		paddingLeft:'50px',
		margin: '25px 0'
	}
}));

const InputTextPoint = (props) => {

	const { title, att } = props;

	const classes = useStyles();
	const [combinedState, setForm] = useContext(CombinedContext);
	

	const formValue = (att) => {
		const form = combinedState.point.point_form;
		if (att === 'kp') {
			return isNaN(form.kp) ? form.kp : Number(form.kp).toFixed(3);
		}
		if (att === 'track') return !form.is_inside_sncf ? 'Non modifiable' : form.track ? form.track : '';
		return form[att] ? form[att] : '';
	};

	const [value, setValue] = useState(formValue(att));


	const handleChange = (event) => {
		setValue(event.target.value);
		setForm({ type: 'SET_POINT_FORM', point_form: { [att]: event.target.value } });
	};


	return (
		<Grid item md={4} sm={6} xs={12}>
			<div className={classes.grid_contain}>
				<Typography className={classes.label}>{title}</Typography>
				<TextField
					value={(value)}
					onChange={handleChange}
					InputProps={{
						disableUnderline: true,
						className: classes.textfield_text
					}}
				/>
			</div>
		</Grid>
	);
}

export default InputTextPoint;
