import React from 'react';
import './App.css';
import './Mapbox.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';

import Home from './components/Home/Home';
import AdminLogin from './components/Admin/Login';
import AdminHome from './components/Admin/Home';
import AdminPoint from './components/Admin/Point/MainComponent';

import SnackbarComponent from './components/ToolsComponent/Snackbar';
import { themeInstance } from './Theme/Theme';

import CombinedContext from './contexts/CombinedContext';

import useCombinedReducers from './reducers/combinedReducers';

const App = () => {

	const [state, dispatch] = useCombinedReducers();

	return (
		<CombinedContext.Provider value={[state, dispatch]}>
			<ThemeProvider theme={themeInstance}>
				<Router>
					<Switch>
						<Route exact path="/" component={ Home } />
						<Route exact path="/?lat=:lat&lng=:lng&zoom=:zoom" component={ Home } />
						<Route exact path="/admin-geo" component={ AdminLogin } />
						<Route exact path="/admin-geo/home" component={ AdminHome } />
						<Route exact path="/admin-geo/points/:id" component={ AdminPoint } />
						<Route path="*" component={ Home } />
					</Switch>
				</Router>
				<SnackbarComponent />
			</ThemeProvider>
		</CombinedContext.Provider>
	);
}

export default App;
