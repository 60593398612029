import React, { useContext, useState, useEffect } from 'react';
import { Tooltip, Typography, Button, CircularProgress } from '@material-ui/core';
import { Refresh, List } from '@material-ui/icons';
import clsx from 'clsx';
import mapboxgl from 'mapbox-gl';

import { getSearchResults } from '../../../../../api/GET';

import SearchByRailway from './SearchByRailway';
import SearchById from './SearchById';
import SearchByPolygons from './SearchByPolygons';
import ExportPointsModal from '../ExportPointsModal';

import CombinedContext from '../../../../../contexts/CombinedContext';

import useStyles from './styles/Search';

const Search = () => {

	const classes = useStyles();

	const [state, dispatch] = useContext(CombinedContext);
	
	const [modal, setModal] = useState(false);
	const [drawer, setDrawer] = useState(false);
	const [tooltip, setTooltip] = useState(false);
	const [options, setOptions] = useState(0);
	const [searchResult, setSearchResult] = useState([]);
	const [reloadLoading, setReloadLoading] = useState(false);
	const [searchForm, setSearchForm] = useState({
		sncf_line_number: '',
		pk_above: '',
		pk_below: '',
		is_inside_sncf: 'Sélectionner',
		track: '',
		id: '',
		id_above: '',
		id_below: ''
	});
	const [radios, setRadios] = useState({
		sncf_line_number: false,
		pk_above: false,
		pk_below: false,
		is_inside_sncf: false,
		track: false,
		id: false,
		id_above: false,
		id_below: false
	});

	const { draw, geojson, selectedPoints, newPolygon, deletePolygon } = state.mapbox;

	useEffect(() => {
		if (newPolygon || deletePolygon) {
			const res = { features: selectedPoints };
			updateSearch(res);
			dispatch({ type: "SET_MAPBOX_NEW_POLYGON", newPolygon: false });
		}
	}, [newPolygon, deletePolygon]); // eslint-disable-line react-hooks/exhaustive-deps

	const onToggleDrawer = () => {
		setTooltip(Boolean(drawer));
		setDrawer(!drawer);
	}

	const onRefresh = async () => { // https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
		setReloadLoading(true);
		let res = null;
		let url_properties = '';
		switch (options) {
			case 1:
				const { sncf_line_number, pk_above, pk_below, is_inside_sncf, track } = searchForm;
				url_properties += `${radios.sncf_line_number && sncf_line_number ? `line=${sncf_line_number}&` : ''}`;
				url_properties += `${radios.pk_above && pk_above ? `kp[sup]=${pk_above}&` : ''}`;
				url_properties += `${radios.pk_below && pk_below ? `kp[inf]=${pk_below}&` : ''}`;
				url_properties += `${radios.is_inside_sncf && is_inside_sncf !== 'Sélectionner' ? `is_inside_sncf=${is_inside_sncf.toUpperCase() === 'OUI' ? 1 : 0}&` : ''}`;
				url_properties += `${radios.track && track ? `track=${track}&` : ''}`;
				if (!url_properties)
					break;
				res = await getSearchResults(url_properties.substring(0, url_properties.length - 1));
				break;
			case 2:
				const { id, id_above, id_below } = searchForm;
				url_properties += `${radios.id && id ? `id=${id}&` : ''}`;
				url_properties += `${radios.id_above && id_above ? `id[sup]=${id_above}&` : ''}`;
				url_properties += `${radios.id_below && id_below ? `id[inf]=${id_below}&` : ''}`;
				if (!url_properties)
					break;
				res = await getSearchResults(url_properties.substring(0, url_properties.length - 1));
				break;
			case 3:
				return setReloadLoading(false);
			default:
				break;
		}
		updateSearch(res);
	}

	const updateSearch = (points) => {
		const coordinates = [];

		if (deletePolygon) {
			setSearchResult([]);
			dispatch({ type: 'SET_MAPBOX_DELETE_POLYGON', deletePolygon: false });
		} else if (points) {
			setSearchResult(points.features);
			dispatch({ type: 'ACTIVE_SNACKBAR', message: `${points.features.length} points on été trouvé(s)`, severity: 'success' });
			for (let i = 0; i < points.features.length; i++)
				coordinates.push(points.features[i].geometry.coordinates);
		} else {
			dispatch({ type: 'ACTIVE_SNACKBAR', message: "Aucun résultat n'a été trouvé", severity: 'warning' });
			setSearchResult([]);
		}

		if (coordinates.length > 0 && newPolygon === false) {
			const bounds = coordinates.reduce((bounds, coord) => {
				return bounds.extend(coord);
			}, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
			state.mapbox.map.fitBounds(bounds, {
				padding: 50
			});
		}
		setReloadLoading(false);
	}

	const onExport = () => {
		if (searchResult.length === 0) {
			dispatch({ type: 'ACTIVE_SNACKBAR', message: 'Aucun points à exporter', severity: 'warning' });
		} else
			setModal(true);
	}

	const draw_container = () => {
		return (
			<div className={!drawer ? classes.hide : undefined}>
				<div className={classes.drawer_content}>
					<Button classes={{ root: classes.drawer_toggle_attributes }} onClick={() => onToggleSearchAttributes(3)}>
						Dans une zone
					</Button>
					<div className={options !== 3 ? classes.hide : classes.drawer_tools_icon_container}>
                        <SearchByPolygons draw={draw} geojson={geojson} selectedPoints={selectedPoints} />
					</div>
				</div>
			</div>
		);
	}

	const onToggleSearchAttributes = (attribute_id) => {
		if (options === attribute_id)
			setOptions(0);
		else
			setOptions(attribute_id);
		draw.deleteAll();
		setSearchResult([]);
    }
    
    return (
        <Tooltip
            title="Recherche d'un point par attributs"
            placement="right"
            fontSize={20}
            onOpen={() => setTooltip(true)}
            onClose={() => setTooltip(false)}
            open={tooltip && !drawer}
        >
            <div id="SearchByAtributes">
                <div className={drawer ? classes.drawer_root : undefined}>
                    {!drawer ?
                        <div className={classes.control_button_container} onClick={onToggleDrawer}>
                            <List className={classes.controls_icon} />
                        </div>
                    :
                        <div>
                            <div className={classes.drawer_title_main_container}>
                                <div className={classes.drawer_title_container}>
                                    <div className={classes.active_search_by_points} onClick={onToggleDrawer}>
                                        <List className={classes.controls_icon} />
                                    </div>
                                    <Typography className={classes.drawer_title}>RECHERCHE DE POINTS</Typography>
                                </div>
                                {reloadLoading ?
                                    <CircularProgress className={classes.reload_loading} size={20} />
                                :
                                    <Refresh className={classes.reload_icon} onClick={onRefresh} />
                                }
                            </div>
                            <div className={classes.drawer_result_container}>
                                <Typography className={classes.drawer_result_title}>{searchResult.length} point(s)</Typography>
                            </div>
                            <div className={classes.drawer_content}>
                                <Button classes={{ root: classes.drawer_toggle_attributes }} onClick={() => onToggleSearchAttributes(1)}>
                                    Par critères ferroviaires
                                </Button>
                                {options === 1 && <SearchByRailway radios={radios} setRadios={setRadios} searchForm={searchForm} setSearchForm={setSearchForm} />}

                                <Button classes={{ root: classes.drawer_toggle_attributes }} onClick={() => onToggleSearchAttributes(2)}>
                                    Par identifiant
                                </Button>
                                {options === 2 && <SearchById radios={radios} setRadios={setRadios} searchForm={searchForm} setSearchForm={setSearchForm} />}
                            </div>
                        </div>
                    }
                    {draw_container()}
                    <div className={drawer ? clsx(classes.drawer_content, classes.drawer_bottom_container) : classes.hide}>
                        <Button classes={{ root: classes.export_button }} onClick={onExport}>Exporter</Button>
                    </div>
                    <ExportPointsModal open={modal} setOpen={setModal} selectedPoints={searchResult} />
                </div>
            </div>
        </Tooltip>
    );
}

export default Search;
