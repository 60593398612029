import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modal_close_button: {
		cursor: 'pointer',
		color: theme.palette.ghost,
		height: 50,
		width: 50,
		alignSelf: 'flex-end',
		margin: '10px 10px',

		'&:hover': {
			color: 'gray'
		}
	},
	title: {
		fontWeight: 'bold',
		marginBottom: 25,
		fontSize: 15
    },
    loading: {
        marginTop: 25,
		marginBottom: 25
    },
	button: {
		backgroundColor: theme.palette.blue_flash,
		color: 'white',
		width: 200,
		height: 50,
		textTransform: 'none',
		fontWeight: 'bold',
		marginTop: 25,
		marginBottom: 25,
		cursor: 'pointer',
		border: 0,
		outline: 0,
		borderRadius: 5,

		'&:hover': {
			opacity: 0.5
		}
	},
	disabled_button: {
		backgroundColor: theme.palette.ghost,
		color: 'white',
		width: 200,
		height: 50,
		textTransform: 'none',
		fontWeight: 'bold',
		marginTop: 25,
		marginBottom: 25,
		cursor: 'pointer',
		border: 0,
		outline: 0,
		borderRadius: 5,

		'&:hover': {
			cursor: 'not-allowed'
		}
	},
	modal: {
		display:'flex',
		alignItems:'center',
		justifyContent:'center'
	},
	modal_root: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'white',
		outline: 'none',
		boxShadow: `inset 0 1px 1px rgba(0,0,0,.075),0 0 10px gray`,
		height: '60%',
		width: '50%',

		"@media (max-width: 1024px)": {
			width: '65%'
		},
		
		"@media (max-width: 768px)": {
			width: '80%'
		},
		
		"@media (max-width: 425px)": {
			width: '95%'
		}
	},
	modal_content_container: {
        display: 'flex',
        flexDirection: 'column',
		alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
		padding: '0px 150px 0px 150px',

		"@media (max-width: 1024px)": {
			padding: '0px 100px 0px 100px'
		},
		
		"@media (max-width: 768px)": {
			padding: '0px 50px 0px 50px'
		},
		
		"@media (max-width: 425px)": {
			padding: '0px 25px 0px 25px'
		}
	},
    root_dropZone: {
        height: '100%',
        width: '100%',
        position: 'relative'
    },
    dropzone_input: {
        opacity: 0,
        height: '100%',
        width: '100%',
        position: 'relative',
        cursor: 'pointer',
        zIndex: 1
    },
    input_dropZone: {
        border: `2px dashed ${theme.palette.ghost}`,
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    },
    dropzone_title: {
        color: theme.palette.ghost,
        fontWeight: 'bold'
	}
}));

export default useStyles;
